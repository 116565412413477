import React, { ReactElement } from "react";

import AddIcon from '@mui/icons-material/Add';

import ChipList from "../../chipList";
import MultiSelectCalloutWithSearch from "./multiSelectCalloutWithSearch";

import { IMultiSelectItem } from "modules/common/models";
import "./../styles/calloutMultiselect.sass";

interface IMultiselectAndChipsWithSearch<T extends IMultiSelectItem<T>> {
    defaultOptions: IMultiSelectItem<T>[];
    selectedOptions?: T[];
    containerClassName?: string;
    buttonClassName?: string;
    buttonText?: string;
    truncateAtIndex?: number;
    showEmptyChip?: boolean;
    onChange: (items: T[]) => void;
    nestingAllowed?: boolean;
    footerComponent?: ReactElement;
    calloutContainerStyle?: React.CSSProperties;
    searchPlaceholder?: string;
    emptyChipText?: string;
    buttonCssStyle?: React.CSSProperties;
    disableOptions?: boolean;
    calloutFooterStyle?: React.CSSProperties;
}

/**
 * Button and list component
 * - button opens multi-select
 * - selections displayed in chips list
 */
const MultiselectAndChipsWithSearch = <TItem extends IMultiSelectItem<TItem>> (props: IMultiselectAndChipsWithSearch<TItem>) => {
    const {
        defaultOptions,
        containerClassName,
        selectedOptions = [],
        buttonClassName,
        buttonText,
        onChange,
        truncateAtIndex = 4,
        nestingAllowed = true,
        showEmptyChip = false,
        footerComponent,
        calloutContainerStyle,
        searchPlaceholder,
        emptyChipText,
        buttonCssStyle,
        disableOptions,
        calloutFooterStyle
    } = props;

    const onUpdateItems = (item: TItem) => {
        const isAlreadySelected: boolean = selectedOptions.map(opt => opt.id).includes(item.id);

        const updatedItemList = isAlreadySelected
        ? selectedOptions.filter(i => i.id !== item.id)
        : [...selectedOptions, item]

        onChange(updatedItemList);
    };

    return (
        <div className={`${containerClassName || ""}`}>
            <div style={{ width: "100%" }}>
                {defaultOptions.length !== 0 &&
                    <MultiSelectCalloutWithSearch
                        defaultOptions={defaultOptions}
                        containerClassName={containerClassName}
                        selectedOptions={selectedOptions}
                        onChange={onChange}
                        buttonStyle={{
                            startIcon: <AddIcon />,
                            ...(buttonText && {buttonText}),
                            ...(buttonClassName && {buttonClassName}),
                            ...(buttonCssStyle && {buttonCssStyle})
                        }}
                        nestingAllowed={nestingAllowed}
                        footerComponent={footerComponent}
                        calloutContainerStyle={calloutContainerStyle}
                        searchBarText={searchPlaceholder}
                        disableOptions={disableOptions}
                        calloutFooterStyle={calloutFooterStyle}
                    />
                }
            </div>
            <div className="chip-list-container">
                <ChipList
                    closable
                    onClose={onUpdateItems}
                    items={selectedOptions}
                    truncateAtIndex={truncateAtIndex}
                    showEmptyChip={showEmptyChip}
                    emptyLabel={emptyChipText}
                />
            </div>
        </div>
    );
};

export default MultiselectAndChipsWithSearch;
