import React, { ReactElement, useState } from "react";
import { Button } from "@mui/material";
import CalloutWithSearch from "../../calloutWithSearch";
import { MultiSelectContent } from "./multiSelectContent";
import { IMultiSelectItem } from "modules/common/models";

interface IMultiSelectCallout<T extends IMultiSelectItem<T>> {
    defaultOptions: IMultiSelectItem<T>[];
    selectedOptions: T[];
    onChange: (items: T[]) => void;
    onClose?: () => void;
    nestingAllowed?: boolean;
    buttonStyle?: IMultiSelectButtonStyle;
    containerClassName?: string;
    searchBarText?: string;
    footerComponent?: ReactElement;
    calloutContainerStyle?: React.CSSProperties;
    disableOptions?: boolean;
    calloutFooterStyle?: React.CSSProperties;
}

interface IMultiSelectButtonStyle {
    buttonClassName?: string;
    buttonText?: string;
    buttonVariant?: "text" | "outlined";
    startIcon?: ReactElement;
    endIcon?: ReactElement;
    buttonCssStyle?: React.CSSProperties;
}

const defaultButtonStyle: IMultiSelectButtonStyle = {
    buttonClassName: "",
    buttonText: "Add/Edit Topics",
    buttonVariant: "text",
};

const MultiSelectCalloutWithSearch = <TItem extends IMultiSelectItem<TItem>>(props: IMultiSelectCallout<TItem>) => {
    const {
        defaultOptions,
        containerClassName,
        selectedOptions = [],
        onChange,
        onClose,
        buttonStyle,
        nestingAllowed = true,
        searchBarText = "Search topic group or topic",
        footerComponent,
        calloutContainerStyle,
        disableOptions,
        calloutFooterStyle
    } = props;

    const [filteredOptions, setFilteredOptions] = useState<IMultiSelectItem<TItem>[]>([...defaultOptions]);
    const [anchorEl, setAnchorEl] = useState(null);
    const {
        buttonClassName,
        buttonText,
        buttonVariant,
        startIcon,
        endIcon,
        buttonCssStyle
    } = { ...defaultButtonStyle, ...buttonStyle };

    const resetItems = () => setFilteredOptions([...defaultOptions]);

    const onCalloutClose = (anchorEl: any) => {
        setAnchorEl(anchorEl);

        if (onClose) onClose();
    }

    const getAllItems = (): TItem[] => {
        const items = nestingAllowed
            ? filteredOptions.filter(opt => opt.children).flatMap(opt => opt.children!)
            : filteredOptions.map(opt => opt as TItem);

        return items ?? [];
    };

    const onToggleAll = (itemsSelected: boolean) => {
        const newSelection = itemsSelected ? [] : getAllItems();
        onChange(newSelection);
    };

    const onSearch = (searchText: string) => {
        const matchesText = (myText: string, textToCompare: string): boolean =>
            myText.trim().toLowerCase().includes(
                textToCompare.trim().toLowerCase()
            );

        const text = searchText.trim().toLowerCase();
        if (text.length <= 0) return resetItems();

        let newOptions = defaultOptions.filter(parent => {
            const childMatchesText = parent.children?.some(child => matchesText(child.name, searchText));
            return matchesText(parent.name, searchText) || childMatchesText;
        });

        if (nestingAllowed) {
            newOptions = newOptions.map(parent => {
                const children = matchesText(parent.name, searchText)
                    ? parent.children
                    : parent.children?.filter(child => matchesText(child.name, searchText));

                return { ...parent, children };
            });
        }

        setFilteredOptions(newOptions);
    };


    const getCalloutFooter = (): JSX.Element => {
        if (footerComponent) return footerComponent;

        const anySelections = selectedOptions.length > 0;

        return <div>
            <Button
                color="primary"
                style={{ float: "right" }}
                onClick={() => onToggleAll(anySelections)}
            >
                {anySelections ? "Unselect" : "Select"} All
            </Button>
        </div>;
    };

    return (
        <div className={containerClassName ?? ""}>
            <div style={{ width: "100%" }}>
                <Button
                    color="primary"
                    style={{ height: "40px", ...buttonCssStyle }}
                    onClick={(event: any) => { setAnchorEl(event.currentTarget); resetItems(); }}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    className={buttonClassName}
                    variant={buttonVariant}
                >
                    {buttonText}
                </Button>
            </div>
            <CalloutWithSearch
                performSearch={onSearch}
                placeholder={searchBarText}
                anchorEl={anchorEl}
                setAnchorEl={onCalloutClose}
                highlight={false}
                footer={getCalloutFooter()}
                searchOnType
                calloutContainerStyle={calloutContainerStyle}
                calloutFooterStyle={calloutFooterStyle}
            >
                <MultiSelectContent
                    availableOptions={filteredOptions}
                    selectedOptions={selectedOptions}
                    onChange={onChange}
                    nestingAllowed={nestingAllowed}
                    disableOptions={disableOptions}
                />
            </CalloutWithSearch>
        </div>
    );
};

export default MultiSelectCalloutWithSearch;
