function ShallowEquals(objA: any, objB: any): boolean {
    if (objA === objB)
        return true;

    if ((!objA && objB) || (!objB && objA))
        return false;

    if (objA && objB && Object.keys(objA).length !== Object.keys(objB).length)
        return false;

    for (const key in objA) {
        if (objA[key] !== objB[key]) {
            return false
        }
    }

    return true;
}

export default ShallowEquals;
