import * as React from "react";

import { Body } from "modules/common/components/authoring/body";

import Label from "./common/label";


function TinyMCE({ input, meta, field }) {
  return (
    <div className="tinymce">
      {field.label &&
        <Label>{field.label}</Label>
      }
      <Body
        body={input.value}
        livePreview
        floatingToolbar
        lcid="en-us"
        onChange={(value) => input.onChange(value)}
      />
    </div>
  );
}

export default TinyMCE;
