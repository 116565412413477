import React from "react";

import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Popover from "@mui/material/Popover";

import "./callout.sass";

/*
  This component is designed to mirror the existing Callout component.
  The difference is that this one has a search bar at the top, which you can pass functions in for.
  It displays as a Popover, meaning it'll open a menu when activated.

  Note:
  MUI has a component called Autocomplete that does the same as this component. We'll want to
  use Autocomplete when possible...
*/
const CalloutWithSearch: React.FunctionComponent<ICalloutWithSearchProps> = props => {
  const [searchText, setSearchText] = React.useState("");

  const onTextChange = (ev) => {
    setSearchText(ev.target.value);
    if(props.searchOnType)
      props.performSearch(ev.target.value);
  }

  const onKeyUp = (key) => {
    if (key.keyCode === 13) {
      props.performSearch(searchText);
    }
  }

  return (
    <Popover
      open={!!props.anchorEl}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          boxShadow: "none",
          overflow: "visible"
        }
      }}
      onClose={() => props.setAnchorEl(null)}
    >
      <div style={props.arrowStyle} className="callout-arrow"></div>
      <div className="callout" style={{ width: "300px", ...props.calloutContainerStyle }}>
        <div className="callout-header">
        <TextField
          autoFocus
          variant="outlined"
          size="small"
          fullWidth
          value={searchText}
          placeholder={props.placeholder}
          error={props.highlight}
          InputProps={{
            startAdornment: <SearchIcon style={{fill: "#666666", marginRight: "5px"}} />,
            endAdornment: searchText &&
                <CloseIcon
                    id="callout-search-close"
                    htmlColor="#666666"
                    onClick={() => {
                        setSearchText("");
                        props.performSearch("");
                    }}
                />
          }}
          onChange={onTextChange}
          onKeyUp={onKeyUp}
        />
        </div>
        <div className="callout-content">
          {props.children}
        </div>
        {!!props.footer &&
          <div className="callout-footer" style={{ ...props.calloutFooterStyle }}>
            {props.footer}
          </div>
        }
      </div>
    </Popover>
  );
}


interface ICalloutWithSearchProps {
  anchorEl: any;
  placeholder: string;
  setAnchorEl: (anchorEl: any) => void;
  arrowStyle?: React.CSSProperties;
  footer?: JSX.Element;
  performSearch: (input: string) => void;
  searchOnType: boolean;
  highlight: boolean;
  calloutContainerStyle?: React.CSSProperties;
  calloutFooterStyle?: React.CSSProperties;
}


export default CalloutWithSearch;
