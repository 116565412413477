import React from "react";

import { Button, Paper } from "@mui/material";
import HoverText from "modules/documents/components/action-buttons/hoverText";
import { AttachedFile } from "modules/gallery";

import "./fileAttachmentDisplay.sass";
import { postsApi } from "api/instances";

interface ComponentProps {
    attachments: AttachedFile[];
}

const MAX_FILE_NAME_DISPLAY_LEN = 37;

const FileAttachmentDisplay: React.FunctionComponent<ComponentProps> = (props) => {
    const getTitle = (): string => {
        if (props.attachments.length === 0) return "Attach files";

        return props.attachments.length > 1
            ? `Attached files (${props.attachments.length})`
            : "Attached file (1)";
    };

    const openDocument = (file: AttachedFile) => {
        if (
            file.fileUrl &&
            (file.fileExtension === "png" || file.fileExtension === "jpg" || file.fileExtension === "jpeg" || file.fileExtension === "pdf")
        ) {
            window.open(file.fileUrl);
        }
        else if (file.fileUrl) {
            postsApi.DownloadFile(file.fileUrl, file.fileName, file.fileExtension);
        }
    };

    const getFittingName = (file: AttachedFile): JSX.Element => {
        const name = file.fileName;

        return name.length > MAX_FILE_NAME_DISPLAY_LEN
            ? <HoverText
                popoverProps={{ anchorOrigin: { horizontal: "left", vertical: "center" } }}
                underline={false}
                label={`${name.substring(0, MAX_FILE_NAME_DISPLAY_LEN)}...`}
            >
                
                <span className="file-attachment-name truncated">{name}</span>
            </HoverText>
            : <span>{name}</span>;
    };

    const getItem = (fileAttachment: AttachedFile) => {
        return (
            <Button
                key={fileAttachment.ospreyId}
                className="file-attachment-display"
                onClick={() => openDocument(fileAttachment)}
            >
                {getFittingName(fileAttachment)}
            </Button>
        );
    };

    return (
        <Paper elevation={0} className={"file-attachment-section"}>
            <div className="file-attachment-header">
                {getTitle()}
            </div>
                {(props.attachments || [])
                    .map((file: AttachedFile) => getItem(file))}
        </Paper>
    );
};

export default FileAttachmentDisplay;