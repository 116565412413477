import { Action } from 'redux';
import { CompleteAction, ActionCreator } from 'typedActions';
import { Post, PostFeedFilters, PostFeedItem, PostListingPage } from './models';
import { CustomFileData, MediaFile, MoveFilesWithSrc, SortGroupName } from 'modules/gallery';

export const DELETE_IMAGES_FROM_LIBRARY_INIT = 'DELETE_IMAGES_FROM_LIBRARY_INIT';
export interface DeleteImagesFromLibraryInit extends Action {}
export const DeleteImagesFromLibraryInit = ActionCreator<DeleteImagesFromLibraryInit>(DELETE_IMAGES_FROM_LIBRARY_INIT);

export const DELETE_IMAGES_FROM_LIBRARY_COMPLETE = 'DELETE_IMAGES_FROM_LIBRARY_COMPLETE';
export interface DeleteImagesFromLibraryComplete extends CompleteAction {ids?: string[]}
export const DeleteImagesFromLibraryComplete = ActionCreator<DeleteImagesFromLibraryComplete>(DELETE_IMAGES_FROM_LIBRARY_COMPLETE);

export const SET_EMAIL_NOTIFICATION_FREQUENCY = "SET_EMAIL_NOTIFICATION_FREQUENCY";
export interface SetEmailNotificationFrequency extends Action {
    frequency: number;
}
export const CreateSetEmailNotificationFrequency = ActionCreator<SetEmailNotificationFrequency>(SET_EMAIL_NOTIFICATION_FREQUENCY);


export const SET_EMAIL_NOTIFICATION_HOURS = "SET_EMAIL_NOTIFICATION_HOURS";
export interface SetEmailNotificationHours extends Action {
    hours: number
}
export const CreateSetEmailNotificationHours = ActionCreator<SetEmailNotificationHours>(SET_EMAIL_NOTIFICATION_HOURS);


export const SET_SMS_NOTIFICATION_FREQUENCY = "SET_SMS_NOTIFICATION_FREQUENCY";
export interface SetSMSNotificationFrequency extends Action {
    frequency: number;
}
export const CreateSetSMSNotificationFrequency = ActionCreator<SetSMSNotificationFrequency>(SET_SMS_NOTIFICATION_FREQUENCY);


export const SET_SMS_NOTIFICATION_HOURS = "SET_SMS_NOTIFICATION_HOURS";
export interface SetSMSNotificationHours extends Action {
    hours: number
}
export const CreateSetSMSNotificationHours = ActionCreator<SetSMSNotificationHours>(SET_SMS_NOTIFICATION_HOURS);

export const GET_MENTIONS_USERS = "GET_MENTIONS_USERS";
export interface GetMentionsUsers extends Action {
    filter: string;
}
export const GetMentionsUsers = ActionCreator<GetMentionsUsers>(GET_MENTIONS_USERS);

export const SET_COMMENTING_ENABLED = "SET_COMMENTING_ENABLED";
export interface SetCommentingEnabled extends Action {
    enabled: boolean;
}
export const CreateSetCommentingEnabledAction = ActionCreator<SetCommentingEnabled>(SET_COMMENTING_ENABLED);

export const SET_REACTING_ENABLED = "SET_REACTING_ENABLED";

export interface SetReactingEnabled extends Action {
    enabled: boolean;
}

export const CreateSetReactingEnabledAction = ActionCreator<SetReactingEnabled>(SET_REACTING_ENABLED);

export const SET_NOTIFICATIONS_ENABLED = "SET_NOTIFICATIONS_ENABLED";
export interface SetNotificationsEnabled extends Action {
    enabled: boolean;
}
export const CreateSetNotificationsEnabledAction = ActionCreator<SetNotificationsEnabled>(SET_NOTIFICATIONS_ENABLED);

export const SET_EMAIL_CHANNEL_ENABLED = "SET_EMAIL_CHANNEL_ENABLED";
export interface SetEmailChannelEnabled extends Action {
    enabled: boolean;
}
export const CreateSetEmailChannelEnabled = ActionCreator<SetEmailChannelEnabled>(SET_EMAIL_CHANNEL_ENABLED);

export const SET_SMS_CHANNEL_ENABLED = "SET_SMS_CHANNEL_ENABLED";
export interface SetSMSChannelEnabled extends Action {
    enabled: boolean;
}
export const CreateSetSMSChannelEnabled = ActionCreator<SetSMSChannelEnabled>(SET_SMS_CHANNEL_ENABLED);

export const SET_CHATBOT_NOTIFICATIONS_ENABLED = "SET_CHATBOT_NOTIFICATIONS_ENABLED";
export interface SetChatbotNotificationsEnabled extends Action {
    enabled: boolean;
}
export const CreateSetChatbotNotificationsEnabled = ActionCreator<SetChatbotNotificationsEnabled>(SET_CHATBOT_NOTIFICATIONS_ENABLED);

export const SET_MOBILE_NOTIFICATIONS_ENABLED = "SET_MOBILE_NOTIFICATIONS_ENABLED";
export interface SetMobileNotificationsEnabled extends Action {
    enabled: boolean;
}
export const CreateSetMobileNotificationsEnabled = ActionCreator<SetMobileNotificationsEnabled>(SET_MOBILE_NOTIFICATIONS_ENABLED);

export const CREATE_NEW_DRAFT = 'CREATE_NEW_DRAFT';
export interface CreateNewDraft extends Action {};
export const CreateNewDraft = ActionCreator<CreateNewDraft>(CREATE_NEW_DRAFT);

export const CREATE_NEW_DRAFT_COMPLETE = 'CREATE_NEW_DRAFT_COMPLETE';
export interface CreateNewDraftComplete extends CompleteAction { postId: string };
export const CreateNewDraftComplete = ActionCreator<CreateNewDraftComplete>(CREATE_NEW_DRAFT_COMPLETE);

export const SET_NEW_DRAFT = 'SET_NEW_DRAFT';
export interface SetNewDraft extends Action { post: Partial<Post> };
export const SetNewDraft = ActionCreator<SetNewDraft>(SET_NEW_DRAFT);

export const SUBMIT_POST = 'SUBMIT_POST';
export interface SubmitPost extends Action {};
export const SubmitPost = ActionCreator<SubmitPost>(SUBMIT_POST);

export const SUBMIT_POST_COMPLETE = 'SUBMIT_POST_COMPLETE';
export interface SubmitPostComplete extends CompleteAction {};
export const SubmitPostComplete = ActionCreator<SubmitPostComplete>(SUBMIT_POST_COMPLETE);

export const EDIT_POST_IN_POST_LIST = 'EDIT_POST_IN_POST_LIST';
export interface EditPostInPostList extends Action { postId: string };
export const EditPostInPostList = ActionCreator<EditPostInPostList>(EDIT_POST_IN_POST_LIST);

export const CLEAR_POST_FEED = "CLEAR_POST_FEED";
export interface ClearPostFeed extends Action {}
export const ClearPostFeed = ActionCreator<ClearPostFeed>(CLEAR_POST_FEED);

export const CLEAR_POST_FEED_FILTERS = "CLEAR_POST_FEED_FILTERS";
export interface ClearPostFeedFilters extends Action {}
export const ClearPostFeedFilters = ActionCreator<ClearPostFeedFilters>(CLEAR_POST_FEED_FILTERS);

export const CLEAR_EDITOR = 'CLEAR_EDITOR';
export interface ClearEditor extends Action { };
export const ClearEditor = ActionCreator<ClearEditor>(CLEAR_EDITOR);

export const FETCH_DRAFT_POST_LIST = 'FETCH_DRAFT_POST_LIST';
export interface FetchDraftPostList extends Action { };
export const FetchDraftPostList = ActionCreator<FetchDraftPostList>(FETCH_DRAFT_POST_LIST);

export const FETCH_DRAFT_POST_LIST_COMPLETE = 'FETCH_DRAFT_POST_LIST_COMPLETE';
export interface FetchDraftPostListComplete extends CompleteAction { page: PostListingPage };
export const FetchDraftPostListComplete = ActionCreator<FetchDraftPostListComplete>(FETCH_DRAFT_POST_LIST_COMPLETE);

export const FETCH_EXPIRED_POST_LIST = 'FETCH_EXPIRED_POST_LIST';
export interface FetchExpiredPostList extends Action {};
export const FetchExpiredPostList = ActionCreator<FetchExpiredPostList>(FETCH_EXPIRED_POST_LIST);

export const FETCH_EXPIRED_POST_LIST_COMPLETE = 'FETCH_EXPIRED_POST_LIST_COMPLETE';
export interface FetchExpiredPostListComplete extends CompleteAction { page: PostListingPage };
export const FetchExpiredPostListComplete = ActionCreator<FetchExpiredPostListComplete>(FETCH_EXPIRED_POST_LIST_COMPLETE);

export const FETCH_PUBLISHED_POST_LIST = 'FETCH_PUBLISHED_POST_LIST';
export interface FetchPublishedPostList extends Action {};
export const FetchPublishedPostList = ActionCreator<FetchPublishedPostList>(FETCH_PUBLISHED_POST_LIST);

export const FETCH_PUBLISHED_POST_LIST_COMPLETE = 'FETCH_PUBLISHED_POST_LIST_COMPLETE';
export interface FetchPublishedPostListComplete extends CompleteAction { page: PostListingPage };
export const FetchPublishedPostListComplete = ActionCreator<FetchPublishedPostListComplete>(FETCH_PUBLISHED_POST_LIST_COMPLETE);

export const FETCH_SCHEDULED_POST_LIST = 'FETCH_SCHEDULED_POST_LIST';
export interface FetchScheduledPostList extends Action {};
export const FetchScheduledPostList = ActionCreator<FetchScheduledPostList>(FETCH_SCHEDULED_POST_LIST);

export const FETCH_SCHEDULED_POST_LIST_COMPLETE = 'FETCH_SCHEDULED_POST_LIST_COMPLETE';
export interface FetchScheduledPostListComplete extends CompleteAction { page: PostListingPage };
export const FetchScheduledPostListComplete = ActionCreator<FetchScheduledPostListComplete>(FETCH_SCHEDULED_POST_LIST_COMPLETE);

export const FETCH_SUBMISSION_POST_LIST = 'FETCH_SUBMISSION_POST_LIST';
export interface FetchSubmissionPostList extends Action {};
export const FetchSubmissionPostList = ActionCreator<FetchSubmissionPostList>(FETCH_SUBMISSION_POST_LIST);

export const FETCH_SUBMISSION_POST_LIST_COMPLETE = 'FETCH_SUBMISSION_POST_LIST_COMPLETE';
export interface FetchSubmissionPostListComplete extends CompleteAction { page: PostListingPage };
export const FetchSubmissionPostListComplete = ActionCreator<FetchScheduledPostListComplete>(FETCH_SUBMISSION_POST_LIST_COMPLETE);

export const FETCH_DRAFT_POST = 'FETCH_DRAFT_POST';
export interface FetchDraftPost extends Action { };
export const FetchDraftPost = ActionCreator<FetchDraftPost>(FETCH_DRAFT_POST);

export const FETCH_DRAFT_POST_COMPLETE = 'FETCH_DRAFT_POST_COMPLETE';
export interface FetchDraftPostComplete extends CompleteAction { post: Post | null};
export const FetchDraftPostComplete = ActionCreator<FetchDraftPostComplete>(FETCH_DRAFT_POST_COMPLETE);

export const FETCH_ALL_POST_LIST = 'FETCH_ALL_POST_LIST';
export interface FetchAllPostList extends Action {};
export const FetchAllPostList = ActionCreator<FetchAllPostList>(FETCH_ALL_POST_LIST);

export const FETCH_ALL_POST_LIST_COMPLETE = 'FETCH_ALL_POST_LIST_COMPLETE';
export interface FetchAllPostListComplete extends CompleteAction { page: PostListingPage };
export const FetchAllPostListComplete = ActionCreator<FetchAllPostList>(FETCH_ALL_POST_LIST_COMPLETE);


export const FETCH_POSTS_FEED = 'FETCH_POSTS_FEED';
export interface FetchPostsFeed extends Action {};
export const FetchPostsFeed = ActionCreator<FetchPostsFeed>(FETCH_POSTS_FEED);

export const FETCH_POSTS_FEED_COMPLETE = 'FETCH_POSTS_FEED_COMPLETE';
export interface FetchPostsFeedComplete extends CompleteAction { filtersApplied: boolean, postsFeed: PostFeedItem[], pageNumber: number };
export const FetchPostsFeedComplete = ActionCreator<FetchPostsFeedComplete>(FETCH_POSTS_FEED_COMPLETE);

export const FETCH_POSTS_FEED_LOCAL = 'FETCH_POSTS_FEED_LOCAL';
export interface FetchPostsFeedLocal extends Action {};
export const FetchPostsFeedLocal = ActionCreator<FetchPostsFeedLocal>(FETCH_POSTS_FEED_LOCAL);

export const FETCH_POSTS_FEED_LOCAL_COMPLETE = 'FETCH_POSTS_FEED_LOCAL_COMPLETE';
export interface FetchPostsFeedLocalComplete extends CompleteAction {};
export const FetchPostsFeedLocalComplete = ActionCreator<FetchPostsFeedLocalComplete>(FETCH_POSTS_FEED_LOCAL_COMPLETE);

export const UPDATE_POST = 'UPDATE_POST';
export interface UpdatePost extends Action { post: Partial<Post> };
export const UpdatePost = ActionCreator<UpdatePost>(UPDATE_POST);

export const UPDATE_POSTS_FEED = 'UPDATE_POSTS_FEED';
export interface UpdatePostsFeed extends Action { postsFeed: PostFeedItem[] };
export const UpdatePostsFeed = ActionCreator<UpdatePostsFeed>(UPDATE_POSTS_FEED);

type postKey = keyof Post;
export const POST_EDIT_FIELD_UPDATE = 'POST_EDIT_FIELD_UPDATE';
export interface FieldUpdate extends Action { field: postKey, value: any };
export const FieldUpdate = ActionCreator<FieldUpdate>(POST_EDIT_FIELD_UPDATE);

export const DRAFT_POST_SAVE = 'DRAFT_POST_SAVE';
export interface DraftPostSave extends Action { }
export const DraftPostSave = ActionCreator<DraftPostSave>(DRAFT_POST_SAVE);

export const DRAFT_POST_SAVE_COMPLETE = 'DRAFT_POST_SAVE_COMPLETE';
export interface DraftPostSaveComplete extends CompleteAction { }
export const DraftPostSaveComplete = ActionCreator<DraftPostSaveComplete>(DRAFT_POST_SAVE_COMPLETE);

export const DRAFT_POST_DELETE = 'DRAFT_POST_DELETE';
export interface DraftPostDelete extends Action { id: string }
export const DraftPostDelete = ActionCreator<DraftPostDelete>(DRAFT_POST_DELETE);

export const DRAFT_POST_DELETE_COMPLETE = 'DRAFT_POST_DELETE_COMPLETE';
export interface DraftPostDeleteComplete extends CompleteAction { id?: string }
export const DraftPostDeleteComplete = ActionCreator<DraftPostDeleteComplete>(DRAFT_POST_DELETE_COMPLETE);

export const DRAFT_POST_DELETE_COMPLETE_BATCH = 'DRAFT_POST_DELETE_COMPLETE_BATCH';
export interface DraftPostDeleteCompleteBatch extends CompleteAction { ids: string[] }
export const DraftPostDeleteCompleteBatch = ActionCreator<DraftPostDeleteCompleteBatch>(DRAFT_POST_DELETE_COMPLETE_BATCH);

export const FETCH_IMAGES_FROM_LIBRARY = 'FETCH_IMAGES_FROM_LIBRARY';
export interface FetchImagesFromLibrary extends Action { }
export const FetchImagesFromLibrary = ActionCreator<FetchImagesFromLibrary>(FETCH_IMAGES_FROM_LIBRARY);

export const FETCH_IMAGES_FROM_LIBRARY_COMPLETE = 'FETCH_IMAGES_FROM_LIBRARY_COMPLETE';
export interface FetchImagesFromLibraryComplete extends CompleteAction { chonkyFileData: CustomFileData[], totalCount: number, totalPages: number, currDir?: MediaFile, folderChain: CustomFileData[], currSortGroup?: SortGroupName, toggleShowFoldersFirst?: boolean, moveFilesForAppBar?: MoveFilesWithSrc }
export const FetchImagesFromLibraryComplete = ActionCreator<FetchImagesFromLibraryComplete>(FETCH_IMAGES_FROM_LIBRARY_COMPLETE);

export const FETCH_DOCUMENTS_FROM_DIRECTORY = 'FETCH_DOCUMENTS_FROM_DIRECTORY';
export interface FetchDocumentsFromDirectory extends Action { }
export const FetchDocumentsFromDirectory = ActionCreator<FetchDocumentsFromDirectory>(FETCH_DOCUMENTS_FROM_DIRECTORY);

export const FETCH_DOCUMENTS_FROM_DIRECTORY_COMPLETE = 'FETCH_DOCUMENTS_FROM_DIRECTORY_COMPLETE';
export interface FetchDocumentsFromDirectoryComplete extends CompleteAction{ files: any[], continuationToken?: string}
export const FetchDocumentsFromDirectoryComplete = ActionCreator<FetchDocumentsFromDirectoryComplete>(FETCH_DOCUMENTS_FROM_DIRECTORY_COMPLETE);

export const UPLOAD_IMAGE = 'UPLOAD_IMAGE';
export interface UploadImage extends Action { }
export const UploadImage = ActionCreator<UploadImage>(UPLOAD_IMAGE);

export const UPLOAD_IMAGE_COMPLETE = 'UPLOAD_IMAGE_COMPLETE';
export interface UploadImageComplete extends CompleteAction { id: string | null, fileName?: string }
export const UploadImageComplete = ActionCreator<UploadImageComplete>(UPLOAD_IMAGE_COMPLETE);


export const IMAGE_SELECTED = 'IMAGE_SELECTED';
export interface ImageSelected extends Action { imageId: string, imageUrl: string }
export const ImageSelected = ActionCreator<ImageSelected>(IMAGE_SELECTED);

export const DOCUMENT_SELECTED = 'DOCUMENT_SELECTED';
export interface DocumentSelected extends Action { docId: string, docUrl: string }
export const DocumentSelected = ActionCreator<DocumentSelected>(DOCUMENT_SELECTED);

export const IMAGE_TRANSFORMED = 'IMAGE_TRANSFORMED';
export interface ImageTransformed extends Action { transforms: { points: number[], zoom?: number } }
export const ImageTransformed = ActionCreator<ImageTransformed>(IMAGE_TRANSFORMED);

export const IMAGE_TWEAK_CONFIRMED = 'IMAGE_TWEAK_CONFIRMED';
export interface ImageTweaksConfirmed extends Action { }
export const ImageTweaksConfirmed = ActionCreator<ImageTweaksConfirmed>(IMAGE_TWEAK_CONFIRMED);

export const CANCEL_IMAGE_CHANGES = 'CANCEL_IMAGE_CHANGES';
export interface CancelImageChanges extends Action { }
export const CancelImageChanges = ActionCreator<CancelImageChanges>(CANCEL_IMAGE_CHANGES);

export const DELETE_EDITOR_POST_IMAGE = 'DELETE_EDITOR_POST_IMAGE';
export interface DeleteEditorPostImage extends Action { }
export const DeleteEditorPostImage = ActionCreator<DeleteEditorPostImage>(DELETE_EDITOR_POST_IMAGE);

export const FETCH_IMAGE_FROM_LIBRARY_COMPLETE = 'FETCH_IMAGE_FROM_LIBRARY_COMPLETE';
export interface FetchImageFromLibraryComplete extends CompleteAction { image: any }
export const FetchImageFromLibraryComplete = ActionCreator<FetchImageFromLibraryComplete>(FETCH_IMAGE_FROM_LIBRARY_COMPLETE);

export const FETCH_DOCUMENT_FROM_DIRECTORY_COMPLETE = 'FETCH_DOCUMENT_FROM_DIRECTORY_COMPLETE';
export interface FetchDocumentFromDirectoryComplete extends CompleteAction { file: any }
export const FetchDocumentFromDirectoryComplete = ActionCreator<FetchDocumentFromDirectoryComplete>(FETCH_DOCUMENT_FROM_DIRECTORY_COMPLETE);

export const EDIT_PLACED_IMAGE = 'EDIT_PLACED_IMAGE'
export interface EditPlacedImage extends Action { }
export const EditPlacedImage = ActionCreator<EditPlacedImage>(EDIT_PLACED_IMAGE);


export const GET_CONTENT_ANALYSIS = "GET_CONTENT_ANALYSIS";
export interface GetContentAnalysis extends Action {}
export const GetContentAnalysis = ActionCreator<GetContentAnalysis>(GET_CONTENT_ANALYSIS);

export const GET_CONTENT_ANALYSIS_COMPLETE = "GET_CONTENT_ANALYSIS_COMPLETE";
export interface GetContentAnalysisComplete extends CompleteAction {}
export const GetContentAnalysisComplete = ActionCreator<GetContentAnalysisComplete>(GET_CONTENT_ANALYSIS_COMPLETE);


export const GET_DRAFT_TRANSLATION = "GET_DRAFT_TRANSLATION";
export interface GetDraftTranslation extends Action {}
export const GetDraftTranslation = ActionCreator<GetDraftTranslation>(GET_DRAFT_TRANSLATION);

export const GET_DRAFT_TRANSLATION_COMPLETE = "GET_DRAFT_TRANSLATION_COMPLETE";
export interface GetDraftTranslationComplete extends CompleteAction {}
export const GetDraftTranslationComplete = ActionCreator<GetDraftTranslationComplete>(GET_DRAFT_TRANSLATION_COMPLETE);


export const UNPUBLISH_POST = 'UNPUBLISH_POST'
export interface UnpublishPost extends Action { }
export const UnpublishPost = ActionCreator<UnpublishPost>(UNPUBLISH_POST);

export const UNPUBLISH_POST_COMPLETE = 'UNPUBLISH_POST_COMPLETE';
export interface UnpublishPostComplete extends CompleteAction {};
export const UnpublishPostComplete = ActionCreator<UnpublishPostComplete>(UNPUBLISH_POST_COMPLETE);

export const PUBLISH_POST = 'PUBLISH_POST'
export interface PublishPost extends Action { }
export const PublishPost = ActionCreator<PublishPost>(PUBLISH_POST);

export const PUBLISH_POST_COMPLETE = 'PUBLISH_POST_COMPLETE'
export interface PublishPostComplete extends CompleteAction {
    validationResult?: {
        errors?: { [key: string]: string[] },
        postTitle?: string,
        postId?: string
    }
}
export const PublishPostComplete = ActionCreator<PublishPostComplete>(PUBLISH_POST_COMPLETE);

export const PUBLISH_SUCCESSFUL = 'PUBLISH_SUCCESSFUL';
export interface PublishSuccessful extends Action { containsVideoContent?: boolean, expectedPublishTime?: string, id?: string }
export const PublishSuccessful = ActionCreator<PublishSuccessful>(PUBLISH_SUCCESSFUL);

export const HIDE_PUBLISH_SUCCESSFUL_DIALOG = 'HIDE_PUBLISH_SUCCESSFUL_DIALOG';
export interface HidePublishSuccessfulDialog extends Action {}
export const HidePublishSuccessfulDialog = ActionCreator<HidePublishSuccessfulDialog>(HIDE_PUBLISH_SUCCESSFUL_DIALOG);

export const FETCH_IDEAL_WORD_COUNT = 'FETCH_IDEAL_WORD_COUNT';
export interface FetchIdealWordCount extends Action { }
export const FetchIdealWordCount = ActionCreator<FetchIdealWordCount>(FETCH_IDEAL_WORD_COUNT);

export const FETCH_IDEAL_WORD_COUNT_COMPLETE = 'FETCH_IDEAL_WORD_COUNT_COMPLETE';
export interface FetchIdealWordCountComplete extends CompleteAction { idealWordCount: number }
export const FetchIdealWordCountComplete = ActionCreator<FetchIdealWordCountComplete>(FETCH_IDEAL_WORD_COUNT_COMPLETE);

export const DISMISS_IDEAL_WORD_COUNT_TIP = 'DISMISS_IDEAL_WORD_COUNT_TIP';
export interface DismissIdealWordCountTip extends Action { }
export const DismissIdealWordCountTip = ActionCreator<DismissIdealWordCountTip>(DISMISS_IDEAL_WORD_COUNT_TIP);


export const SET_POST_TYPE = 'SET_POST_TYPE';
export interface SetPostType extends Action { postType: string }
export const SetPostType = ActionCreator<SetPostType>(SET_POST_TYPE);

export const SET_CHANGED_SINCE_SAVE = 'SET_CHANGED_SINCE_SAVE';
export interface SetChangedSinceSave extends Action { changed: boolean}
export const SetChangedSinceSave = ActionCreator<SetChangedSinceSave>(SET_CHANGED_SINCE_SAVE);

export const SET_SAVING = 'SET_SAVING';
export interface SetSaving extends Action { saving: boolean }
export const SetSaving = ActionCreator<SetSaving>(SET_SAVING);

export const SET_NEW_POST_LIST = 'SET_NEW_POST_LIST'
export interface setNewPostList extends Action { page: PostListingPage }
export const setNewPostList = ActionCreator<setNewPostList>(SET_NEW_POST_LIST);

export const SET_UPLOADING = "SET_UPLOADING";
export interface SetUploading extends Action { uploading: boolean }
export const SetUploading = ActionCreator<SetUploading>(SET_UPLOADING);


export const SET_POST_FEED_FILTERS = "SET_POST_FEED_FILTERS";
export interface SetPostFeedFilters extends Action { filters: Partial<PostFeedFilters> }
export const SetPostFeedFilters = ActionCreator<SetPostFeedFilters>(SET_POST_FEED_FILTERS);

export const SEND_ACKNOWLEDGEMENT = "SEND_ACKNOWLEDGEMENT";
export interface SendAcknowledgement extends Action {}
export const SendAcknowledgement = ActionCreator<SendAcknowledgement>(SEND_ACKNOWLEDGEMENT);

export const SEND_ACKNOWLEDGEMENT_COMPLETE = "SEND_ACKNOWLEDGEMENT_COMPLETE";
export interface SendAcknowledgementComplete extends CompleteAction {}
export const SendAcknowledgementComplete = ActionCreator<SendAcknowledgementComplete>(SEND_ACKNOWLEDGEMENT_COMPLETE);


export const SET_REACTION = "SET_REACTION";
export interface SetReaction extends Action {}
export const SetReaction = ActionCreator<SetReaction>(SET_REACTION);

export const SET_REACTION_COMPLETE = "SET_REACTION_COMPLETE";
export interface SetReactionComplete extends CompleteAction {}
export const SetReactionComplete = ActionCreator<SetReactionComplete>(SET_REACTION_COMPLETE);


export const ADD_COMMENT = "ADD_COMMENT";
export interface AddComment extends Action {}
export const AddComment = ActionCreator<AddComment>(ADD_COMMENT);

export const ADD_COMMENT_COMPLETE = "ADD_COMMENT_COMPLETE";
export interface AddCommentComplete extends CompleteAction {}
export const AddCommentComplete = ActionCreator<AddCommentComplete>(ADD_COMMENT_COMPLETE);

export const ADD_COMMENT_REPLY = "ADD_COMMENT_REPLY";
export interface AddCommentReply extends Action {}
export const AddCommentReply = ActionCreator<AddCommentReply>(ADD_COMMENT_REPLY);

export const ADD_COMMENT_REPLY_COMPLETE = "ADD_COMMENT_REPLY_COMPLETE";
export interface AddCommentReplyComplete extends CompleteAction {}
export const AddCommentReplyComplete = ActionCreator<AddCommentReplyComplete>(ADD_COMMENT_REPLY_COMPLETE);

export const DELETE_COMMENT = "DELETE_COMMENT";
export interface DeleteComment extends Action {}
export const DeleteComment = ActionCreator<DeleteComment>(DELETE_COMMENT);

export const DELETE_COMMENT_COMPLETE = "DELETE_COMMENT_COMPLETE";
export interface DeleteCommentComplete extends CompleteAction {}
export const DeleteCommentComplete = ActionCreator<DeleteCommentComplete>(DELETE_COMMENT_COMPLETE);

export const DESTAR_COMMENT = "DESTAR_COMMENT";
export interface DestarComment extends Action {}
export const DestarComment = ActionCreator<DestarComment>(DESTAR_COMMENT);

export const DESTAR_COMMENT_COMPLETE = "DESTAR_COMMENT_COMPLETE";
export interface DestarCommentComplete extends CompleteAction {}
export const DestarCommentComplete = ActionCreator<DestarCommentComplete>(DESTAR_COMMENT_COMPLETE);


export const EDIT_COMMENT = "EDIT_COMMENT";
export interface EditComment extends Action {}
export const EditComment = ActionCreator<EditComment>(EDIT_COMMENT);

export const EDIT_COMMENT_COMPLETE = "EDIT_COMMENT_COMPLETE";
export interface EditCommentComplete extends CompleteAction {}
export const EditCommentComplete = ActionCreator<EditCommentComplete>(EDIT_COMMENT_COMPLETE);


export const FLAG_COMMENT = "FLAG_COMMENT";
export interface FlagComment extends Action {}
export const FlagComment = ActionCreator<FlagComment>(FLAG_COMMENT);

export const FLAG_COMMENT_COMPLETE = "FLAG_COMMENT_COMPLETE";
export interface FlagCommentComplete extends CompleteAction {}
export const FlagCommentComplete = ActionCreator<FlagCommentComplete>(FLAG_COMMENT_COMPLETE);


export const STAR_COMMENT = "STAR_COMMENT";
export interface StarComment extends Action {}
export const StarComment = ActionCreator<StarComment>(STAR_COMMENT);

export const STAR_COMMENT_COMPLETE = "STAR_COMMENT_COMPLETE";
export interface StarCommentComplete extends CompleteAction {}
export const StarCommentComplete = ActionCreator<StarCommentComplete>(STAR_COMMENT_COMPLETE);


export const SUBSCRIBE_TO_COMMENTS = "SUBSCRIBE_TO_COMMENTS";
export interface SubscribeToComments extends Action {}
export const SubscribeToComments = ActionCreator<SubscribeToComments>(SUBSCRIBE_TO_COMMENTS);

export const SUBSCRIBE_TO_COMMENTS_COMPLETE = "SUBSCRIBE_TO_COMMENTS_COMPLETE";
export interface SubscribeToCommentsComplete extends CompleteAction {}
export const SubscribeToCommentsComplete = ActionCreator<SubscribeToCommentsComplete>(SUBSCRIBE_TO_COMMENTS_COMPLETE);


export const UNSUBSCRIBE_FROM_COMMENTS = "UNSUBSCRIBE_FROM_COMMENTS";
export interface UnsubscribeFromComments extends Action {}
export const UnsubscribeFromComments = ActionCreator<UnsubscribeFromComments>(UNSUBSCRIBE_FROM_COMMENTS);

export const UNSUBSCRIBE_FROM_COMMENTS_COMPLETE = "UNSUBSCRIBE_FROM_COMMENTS_COMPLETE";
export interface UnsubscribeFromCommentsComplete extends CompleteAction {}
export const UnsubscribeFromCommentsComplete = ActionCreator<UnsubscribeFromCommentsComplete>(UNSUBSCRIBE_FROM_COMMENTS_COMPLETE);


export const CLEAR_FETCH_POST = "CLEAR_FETCH_POST";
export interface ClearFetchPost extends Action {}
export const ClearFetchPost = ActionCreator<ClearFetchPost>(CLEAR_FETCH_POST);

export const CLEAR_POST_AUTHORING_ERROR_MESSAGE = "CLEAR_POST_AUTHORING_ERROR_MESSAGE";
export interface ClearPostAuthoringErrorMessage extends Action {}
export const ClearPostAuthoringErrorMessage = ActionCreator<ClearPostAuthoringErrorMessage>(CLEAR_POST_AUTHORING_ERROR_MESSAGE);

export const CLEAR_POST_AUTHORING_SUCCESS_MESSAGE = "CLEAR_POST_AUTHORING_SUCCESS_MESSAGE";
export interface ClearPostAuthoringSuccessMessage extends Action {}
export const ClearPostAuthoringSuccessMessage = ActionCreator<ClearPostAuthoringSuccessMessage>(CLEAR_POST_AUTHORING_SUCCESS_MESSAGE);

export const CLEAR_POST_FEED_ERROR_MESSAGE = "CLEAR_POST_FEED_ERROR_MESSAGE";
export interface ClearPostFeedErrorMessage extends Action {}
export const ClearPostFeedErrorMessage = ActionCreator<ClearPostFeedErrorMessage>(CLEAR_POST_FEED_ERROR_MESSAGE);

export const CLEAR_POST_VIEW_ERROR_MESSAGE = "CLEAR_POST_VIEW_ERROR_MESSAGE";
export interface ClearPostViewErrorMessage extends Action {}
export const ClearPostViewErrorMessage = ActionCreator<ClearPostViewErrorMessage>(CLEAR_POST_VIEW_ERROR_MESSAGE);

export const CLEAR_SHOULD_FETCH = "CLEAR_SHOULD_FETCH";
export interface ClearShouldFetch extends Action {}
export const ClearShouldFetch = ActionCreator<ClearShouldFetch>(CLEAR_SHOULD_FETCH);

export const DELETE_FOLDERS_INIT = 'DELETE_FOLDERS_INIT';
export interface DeleteFoldersInit extends Action {}
export const DeleteFoldersInit = ActionCreator<DeleteFoldersInit>(DELETE_FOLDERS_INIT);