import React from "react";
import { TextField } from "@mui/material";
import DatePicker from "./datePicker";
import moment from "moment";
import EventIcon from '@mui/icons-material/Event';
import ScheduleIcon from '@mui/icons-material/Schedule';
import "../styles/switchAndCollapse.sass";
import { DateView } from "@mui/x-date-pickers";

interface IDateAndTimeProps {
    date?: string;
    label?: string;
    datePlaceholder?: string;
    timePlaceholder?: string;
    minDate?: moment.Moment;
    maxDate?: moment.Moment;
    containerStyle?: React.CSSProperties;
    dateStyle?: React.CSSProperties;
    timeStyle?: React.CSSProperties;
    clearable?: boolean;
    views?: DateView[];
    avoidPickerViewLayoutShift?: boolean;
    onChangeDate: (value: string | undefined) => void;
    showIcons?: boolean;
    initializeDateWithCurrentTime?: boolean;
}

const DateAndTime: React.FunctionComponent<IDateAndTimeProps> = ({
    date,
    label,
    minDate,
    maxDate,
    datePlaceholder,
    dateStyle,
    timeStyle,
    timePlaceholder,
    views,
    clearable = false,
    avoidPickerViewLayoutShift,
    onChangeDate,
    showIcons = false,
    initializeDateWithCurrentTime = false
}) => {
    const timeDisabled = Boolean(date) === false;

    const onChangeTimeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
        const times: number[] = event.target.value.split(":").map(time => Number(time));
        onChangeTime(times[0], times[1]);
    }

    const onChangeTime = (hours: number, minutes: number, dateValue = date) => {
        const publishTime: string = moment(dateValue)
            .set("hour", hours)
            .set("minutes", minutes)
            .toISOString();

        onInnerChangeDate(publishTime);
    }

    const getTime = (): string | undefined | null => {
        let result: string | undefined | null = "";
        if (Boolean(date))
            result = moment(date).format("HH:mm");

        return result;
    }

    const onChangeDatePicker = (value: string | undefined) => {
        if (initializeDateWithCurrentTime && !date) {
            onChangeTime(moment().hours(), moment().minutes(), value);
        }
        else {
            onInnerChangeDate(value);
        }
    }

    const onInnerChangeDate = (value: string | undefined) => {
        if (minDate && moment(value).isBefore(minDate)) {
            onChangeDate(minDate.toISOString());
        }
        else {
            onChangeDate(value);
        }
    }

    return (
        <>
            <div className="date-time-content">
                {label && <label className="picker-label">{label}</label>}
                <DatePicker
                    avoidPickerViewLayoutShift={avoidPickerViewLayoutShift}
                    views={views}
                    minDate={minDate}
                    maxDate={maxDate}
                    onChangeDate={onChangeDatePicker}
                    date={date}
                    placeholder={datePlaceholder}
                    style={dateStyle}
                    clearable={clearable}
                    inputEndAdornment={showIcons ? <EventIcon fontSize="small" color="primary"/> : undefined}
                />
                {
                    Boolean(date)
                        ? <TextField
                            className="time-picker"
                            style={{
                                ...timeStyle,
                            }}
                            sx={{
                                // https://stackoverflow.com/questions/69742543/change-the-clock-icon-color-in-mui-textfield-with-type-time
                                // match the color of the clock icon to the primary color
                                '& input[type="time"]::-webkit-calendar-picker-indicator': {
                                    filter:
                                        'invert(42%) sepia(20%) saturate(1375%) hue-rotate(165deg) brightness(96%) contrast(89%);',
                                },
                            }}
                            variant="outlined"
                            size="small"
                            color="primary"
                            type="time"
                            value={getTime()}
                            disabled={!Boolean(date)}
                            inputProps={{ step: 900 }}
                            InputProps={{
                                style: {
                                    backgroundColor: timeDisabled ? "#f2f2f2" : "#ffffff"
                                }
                            }}
                            onChange={onChangeTimeInternal}
                        />
                        // TextFields with type="time" do not support placeholder so we use an empty TextField instead if there is no valid date
                        : <TextField 
                            className="time-picker"
                            style={{
                                ...timeStyle,
                            }}
                            variant="outlined"
                            size="small"
                            color="primary"
                            placeholder={timePlaceholder ?? "Time"}
                            disabled
                            InputProps={{
                                style: {
                                    backgroundColor: timeDisabled ? "#f2f2f2" : "#ffffff"
                                },
                                endAdornment: showIcons && <ScheduleIcon fontSize="small" color="primary"/>
                            }}
                            value=""
                        />
                }
            </div>
        </>
    );
}

export default DateAndTime;
