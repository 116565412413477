import React from "react";
import EmailContentView from "./emailContentView";
import Preview, { PreviewType } from "modules/common/components/authoring/dialogs/preview";
import { IEmail } from "../models";

interface IEmailPreviewProps {
    show: boolean;
    onClose: () => void;
    email: IEmail;
    activeLcid: string;
}

const EmailPreview: React.FC<IEmailPreviewProps> = ({
    show,
    onClose,
    email,
    activeLcid
}) => {
    return <Preview
        onClose={onClose}
        open={show}
        previews={[{ type: PreviewType.Plain, component: <EmailContentView email={email} activeLcid={activeLcid}/>, label: "DESKTOP" }]}
        previewHelpText="Preview varies based on device, platform and/or email provider."
    />;
};

export default EmailPreview;
