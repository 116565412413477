import React, { useState } from "react";
import { push } from "react-router-redux";
import { connect, ConnectedProps } from "react-redux";
import { IEmail, IEmailFilters, IEmailListingPage, IEmailListItem } from "../models";
import cookie, { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";
import { GlobalApplicationState } from "globalApplicationState";
import { emailsApi } from "api/instances";
import TabContent from "pages/common/tabContent";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import EmailFilters from "./emailFilters";
import ConfirmDialog from "modules/common/components/dialogs/confirmDialog";
import EmailsTable from "./emailsTable";
import { UseSetSnackbarMessages } from "modules/common/hooks/useSetSnackbarMessages";
import EmailPreview from "./emailPreview";

import "../styles/emailsList.sass";

interface IEmailsListProps {
    show: boolean;
    page: IEmailListingPage;
    fetchPage: (filters?: IEmailFilters, pageNumber?: number, pageAmount?: number) => void;
    fetchAllPages: () => void;
    filters: IEmailFilters;
    onUpdateFilters: (newFilters: IEmailFilters) => void;
    defaultEmailFilterValues: IEmailFilters;
    onDownloadEmailActivity: (emailId: string) => void;
    activeLcid: string;
}

const EmailsList: React.FC<PropsWithRedux> = ({
    show,
    page,
    fetchPage,
    fetchAllPages,
    filters,
    onUpdateFilters,
    defaultEmailFilterValues,
    tenantId,
    redirectTo,
    onDownloadEmailActivity,
    activeLcid
}) => {
    const [isLoadingPreview, setIsLoadingPreview] = useState(false);
    const [emailToPreview, setEmailToPreview] = useState<IEmail | undefined>();
    const [showDeleteEmailDialog, setShowDeleteEmailDialog] = useState(false);
    const [showUnsendEmailDialog, setShowUnsendEmailDialog] = useState(false);
    const [emailIdToDelete, setEmailIdToDelete] = useState("");
    const [emailIdToUnsend, setEmailIdToUnsend] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [isUnsending, setIsUnsending] = useState(false);

    const { setErrorMessage, setInfoMessage, setSuccessMessage } = UseSetSnackbarMessages();

    const showIsLoading = page.isFetching || isDeleting || isUnsending;
    const hasFiltersApplied = filters.textToSearch !== defaultEmailFilterValues.textToSearch;

    const onChangeFilters = (newFilters: IEmailFilters) => {
        const rowsPerPage = cookie.getRowsPerPage(ROWS_PER_PAGE_COOKIE_NAMES.EMAILS, 25);

        onUpdateFilters(newFilters);
        onChangePage(0, rowsPerPage, newFilters);
    }

    const onChangePage = (page: number,  rowsPerPage: number, filters: IEmailFilters) => {
        fetchPage(filters, page + 1, rowsPerPage);
    }

    const handleDraftEmailClick = (email: IEmailListItem) => redirectTo("/" + tenantId + "/admin/emails/edit/" + email.id);

    const onPreviewEmail = async (emailToDisplay: IEmailListItem) => {
        setIsLoadingPreview(true);
        
        try {
            const { data } = await emailsApi.getEmail(emailToDisplay.id);
            setEmailToPreview(data);
        } catch (error) {
            setEmailToPreview(undefined);
            setErrorMessage("There was an error with your request. Please try again later.");
        } finally {
            setIsLoadingPreview(false);
        }
    }

    const onDeleteEmail = (email: IEmailListItem) => {
        setEmailIdToDelete(email.id);
        setShowDeleteEmailDialog(true);
    }

    const deleteEmail = async () => {
        setShowDeleteEmailDialog(false);
        setIsDeleting(true);
        try {
            await emailsApi.deleteEmail(emailIdToDelete);
            fetchPage();
            setInfoMessage("Email deleted.");
        }
        catch {
            setErrorMessage("There was an error deleting the email. Please try again later.");
        }
        finally {
            resetDeleteEmailStates();
        }
    }

    const resetDeleteEmailStates = () => {
        setShowDeleteEmailDialog(false);
        setEmailIdToDelete("");
        setIsDeleting(false);
    }

    const onUnsendEmail = (email: IEmailListItem) => {
        setEmailIdToUnsend(email.id);
        setShowUnsendEmailDialog(true);
    }

    const unsendEmail = async () => {
        setShowUnsendEmailDialog(false);
        try {
            setIsUnsending(true);
            await emailsApi.cancelSend(emailIdToUnsend);
            fetchAllPages();
            setSuccessMessage("Your email was cancelled and is now in drafts.");
        }
        catch {
            setErrorMessage("There was an error with cancelling the email. Please try again later");
        }
        finally {
            resetUnsendEmailStates();
        }
    }

    const resetUnsendEmailStates = () => {
        setShowUnsendEmailDialog(false);
        setEmailIdToUnsend("");
        setIsUnsending(false);
    }
    
    const onDuplicateEmail = async (email: IEmailListItem) => {
        try {
            const { data } = await emailsApi.cloneEmail(email.id);
            redirectTo("/" + tenantId + "/admin/emails/edit/" + data);
        }
        catch { 
            setErrorMessage("There was an error with duplicating. Please try again later.");
        }
    }

    return !show
        ? <></>
        : <React.Fragment>
            <TabContent>
                <EmailFilters
                    filters={filters}
                    onChangeFilters={onChangeFilters}
                    onClearFilters={() => onChangeFilters(defaultEmailFilterValues)}
                />
                <EmailsTable
                    show={show}
                    showIsLoading={showIsLoading}
                    page={page}
                    filters={filters}
                    hasFiltersApplied={hasFiltersApplied}
                    activeLcid={activeLcid}
                    onChangeFilters={onChangeFilters}
                    onPreviewEmail={onPreviewEmail}
                    onChangePage={onChangePage}
                    onDownloadEmailActivity={onDownloadEmailActivity}
                    handleDraftEmailClick={handleDraftEmailClick}
                    onDuplicateEmail={onDuplicateEmail}
                    onDeleteEmail={onDeleteEmail}
                    onUnsendEmail={onUnsendEmail}
                />
                <ConfirmDialog
                    title="Delete Email"
                    open={showDeleteEmailDialog}
                    confirmLabel="DELETE"
                    confirmButtonProps={{sx: {backgroundColor: '#dc3838', color: 'white'}}}
                    denyLabel="CANCEL"
                    onConfirm={deleteEmail}
                    onDeny={resetDeleteEmailStates}
                    onClose={resetDeleteEmailStates}
                >
                    You are about to permanently delete this email. This action cannot be undone.
                    <br /><br />
                    Are you sure you want to proceed?
                </ConfirmDialog>
                <ConfirmDialog
                    title="Cancel Send"
                    open={showUnsendEmailDialog}
                    confirmLabel="CANCEL SEND"
                    confirmButtonProps={{sx: {backgroundColor: '#dc3838', color: 'white'}}}
                    denyLabel="CANCEL"
                    onConfirm={unsendEmail}
                    onDeny={resetUnsendEmailStates}
                    onClose={resetUnsendEmailStates}
                >
                    You are about to cancel sending this email. It will be moved back to drafts.
                    <br /><br />
                    Are you sure you want to proceed?
                </ConfirmDialog>
                {
                    emailToPreview &&
                    <EmailPreview
                        onClose={() => setEmailToPreview(undefined)}
                        show={!!emailToPreview}
                        email={emailToPreview}
                        activeLcid={activeLcid}
                    />
                }
                <LoadingOverlay absolute={true} show={isLoadingPreview} />
            </TabContent>
        </React.Fragment>;
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: IEmailsListProps) => ({
        ...ownProps,
        tenantId: state.tenant.id,
    }),
    {
        redirectTo: push
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EmailsList);
