import React, { useLayoutEffect, useState } from "react";
import BasePage from "pages/common/basePage";
import Header from "pages/common/header";
import MainContent from "pages/common/mainContent";
import MoreOptionsEmail from "./moreOptionsEmail";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Button } from "@mui/material";
import { push } from "react-router-redux";
import { GlobalApplicationState } from "globalApplicationState";
import { RouteComponentProps } from "react-router";
import { connect, ConnectedProps } from "react-redux";
import EmailsListing from "./emailsListing";
import { messagingApi, reportsApi } from "api/instances";
import SenderEmailsDialog from "./senderEmailsDialog";
import { actions, Address } from "modules/newsletters";
import AssociatedNewslettersDialog from "modules/newsletters/management/components/associatedNewslettersDialog";
import { IdNamePair } from "modules/common/models";
import { setShouldDisplayNav } from "modules/adminLayout/actionCreator";
import LoadingOverlay from "modules/common/components/loadingOverlay";
import { ApiRequest } from "api/network";
import { FileDownloader } from "utils/fileDownloader";
import SentEmailDialog from "./sentEmailDialog";
import { UseSetSnackbarMessages } from "modules/common/hooks/useSetSnackbarMessages";

interface IEmailsManagementPageProps { }

const EmailsManagementPage: React.FC<PropsWithRedux> = ({
    redirectTo,
    getAssociatedNewsletters,
    setShouldDisplayNav,
    shouldDisplayNav,
    sentConfirmation,
    tenantId
}) => {
    const [showSenderEmailDialog, setShowSenderEmailDialog] = useState(false);
    const [isLoadingAssocNewsletters, setIsLoadingAssocNewsletters] = useState(false);
    const [showAssociatedNewslettersDialog, setShowAssociatedNewslettersDialog] = useState(false);
    const [associatedNewsletters, setAssociatedNewsletters] = useState<IdNamePair<string, string>[]>([]);
    const [associatedSender, setAssociatedSender] = useState<Address | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const activeLcid = "en-us"; // we only support en-us for now

    const { setSuccessMessage, setErrorMessage } = UseSetSnackbarMessages();

    const onDownloadEmailActivity = async (emailId?: string) => {
        reportsApi.SendEmailActivityReportRequest(emailId)
            .then(() => setSuccessMessage("Your report is being generated and will be emailed to you when it’s ready."))
            .catch(() => setErrorMessage("Something went wrong. Please try again."));
    }

    const onCheckAssociatedNewsletters = async (senderDetails: Address) => {
        setIsLoadingAssocNewsletters(true);
        setShowSenderEmailDialog(false);
        setShowAssociatedNewslettersDialog(true);
        const associatedNewsletters = await getAssociatedNewsletters(senderDetails.fromEmailName, senderDetails.fromEmailAddress);
        setAssociatedNewsletters(associatedNewsletters);
        setAssociatedSender(senderDetails);
        setIsLoadingAssocNewsletters(false);
    }

    const onCloseAssociatedNewslettersDialog = () => {
        setShowAssociatedNewslettersDialog(false);
        setShowSenderEmailDialog(true);
    }

    useLayoutEffect(() => {
        if (!shouldDisplayNav) 
            setShouldDisplayNav(true);
        
    }, [shouldDisplayNav, setShouldDisplayNav]);

    const onDownloadReport = async (req: () => ApiRequest<any>, name: string) => {
        setIsLoading(true);
        
        req()
            .then(data => {
                new FileDownloader({ name: `${name}-${new Date().toISOString()}.csv` }).downloadBlob(data);
                setSuccessMessage("Successfully downloaded your report! Check your Downloads folder.");
            })
            .catch(() => setErrorMessage("Something went wrong. Please try again."))
            .finally(() => setIsLoading(false));
    }
    
    return <BasePage fullWidth>
        <LoadingOverlay show={isLoading} />
        <Header
            title="Manage Emails"
            rightComponent={
                <React.Fragment>
                    <MoreOptionsEmail
                        onDownloadEmailActivityLog={() => onDownloadEmailActivity()}
                        onDownloadUserData={() => onDownloadReport(messagingApi.GetEmailUserCsv, "notification-email-user-data")}
                        onDownloadEmailOutbox={() => onDownloadReport(() => messagingApi.GetOutbox("email"), "notification-email-outbox-user-data")}
                        onClickManageSenderEmails={() => setShowSenderEmailDialog(true)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<SendOutlinedIcon />}
                        onClick={() => redirectTo("/" + tenantId + "/admin/emails/edit/new")}
                    >
                        SEND NEW EMAIL
                    </Button>
                </React.Fragment>
            }
        />
        <MainContent>
            <div className="authoring-page">
                <EmailsListing onDownloadEmailActivity={onDownloadEmailActivity} activeLcid={activeLcid}/>
            </div>
            <SenderEmailsDialog
                show={showSenderEmailDialog}
                onClose={() => setShowSenderEmailDialog(false)}
                onErrorMessage={setErrorMessage}
                onCheckAssociatedNewsletters={onCheckAssociatedNewsletters}
            />
            <AssociatedNewslettersDialog
                show={showAssociatedNewslettersDialog} 
                onClose={onCloseAssociatedNewslettersDialog} 
                isLoading={isLoadingAssocNewsletters} 
                associatedNewsletters={associatedNewsletters}
                senderAddressCheck={associatedSender}
                backButtonLabel="Back to manage sender emails"
                backButtonProps={{ variant: "text" }}
            />
            { sentConfirmation.shouldShowDialog && <SentEmailDialog /> }
        </MainContent>
    </BasePage>;
};

const connector = connect(
    (state: GlobalApplicationState, ownProps: RouteComponentProps<IEmailsManagementPageProps>) =>
    ({
        ...ownProps,
        shouldDisplayNav: state.adminLayout.shouldDisplayNav,
        sentConfirmation: state.emails.sentConfirmation,
        tenantId: state.tenant.id,
    }),
    {
        redirectTo: push,
        getAssociatedNewsletters: actions.getAssociatedNewsletters,
        setShouldDisplayNav: setShouldDisplayNav
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(EmailsManagementPage);
