import React from "react";
import { EmailSortStyle, EmailStatus, IEmailFilters, IEmailListingPage, IEmailListItem } from "../models";
import Loading from "modules/common/components/loading";
import ManagementEmptyResults from "modules/common/components/managementEmptyResults";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import EmailOptions from "./emailOptions";
import Paging from "modules/common/components/paging";
import moment from "moment";
import { ROWS_PER_PAGE_COOKIE_NAMES } from "utils/cookie";
import { ITranslatedText } from "modules/common/models";

interface IHeaderCell {
    id: string;
    label: string;
    ascValue?: EmailSortStyle;
    descValue?: EmailSortStyle;
    clickable?: boolean;
}

interface IEmailsTableProps {
    show: boolean;
    showIsLoading: boolean;
    page: IEmailListingPage;
    filters: IEmailFilters;
    hasFiltersApplied: boolean;
    activeLcid: string;
    onChangeFilters: (newFilters: IEmailFilters) => void;
    onChangePage: (page: number,  rowsPerPage: number, filters: IEmailFilters) => void;
    onDownloadEmailActivity: (emailId: string) => void;
    handleDraftEmailClick: (email: IEmailListItem) => void;
    onDuplicateEmail: (email: IEmailListItem) => void;
    onPreviewEmail: (email: IEmailListItem) => void;
    onDeleteEmail: (email: IEmailListItem) => void;
    onUnsendEmail: (email: IEmailListItem) => void;
}

const EmailsTable: React.FC<IEmailsTableProps> = ({
    show,
    showIsLoading,
    page,
    filters,
    hasFiltersApplied,
    activeLcid,
    onChangeFilters,
    onPreviewEmail,
    onChangePage,
    onDownloadEmailActivity,
    handleDraftEmailClick,
    onDuplicateEmail,
    onDeleteEmail,
    onUnsendEmail
}) => {
    const getPublishedTimeTabLabel = () => {
        switch (page.id) {
            case EmailStatus.Draft:
                return "Last modified on";
            case EmailStatus.Scheduled:
                return "Send on";
            case EmailStatus.Sent:
                return "Sent on";
        } 
    }

    const formatSubject = (subject: ITranslatedText[]) => {
        const subjectVal = subject.find(s => s.lcid === activeLcid)?.text || "No subject";

        return subjectVal.length > 100 ? subjectVal.substring(0, 97) + "..." : subjectVal;
    }

    const formatTime = (time?: Date): string => time ? moment(time).format("MMM D, YYYY h:mmA") : "-";

    const onClickHeader = (descValue?: EmailSortStyle, ascValue?: EmailSortStyle) => {
        let updatedFilters = { ...filters };

        if (descValue === undefined || ascValue === undefined) {
            return;
        }

        if (filters.sortBy === descValue) {
            updatedFilters = { ...updatedFilters, sortBy: ascValue };
        } else {
            updatedFilters = { ...updatedFilters, sortBy: descValue };
        }

        onChangeFilters(updatedFilters);
    }

    const getHeader = () => {
        let header: IHeaderCell[] = [
            {
                id: "title",
                label: "Subject",
                ascValue: EmailSortStyle.SubjectAsc,
                descValue: EmailSortStyle.SubjectDesc,
                clickable: true
            }
        ];

        header.push({ id: "options", label: "" });

        header.push({
            id: "publishedTime",
            label: getPublishedTimeTabLabel(),
            ascValue: page.id === EmailStatus.Draft ? EmailSortStyle.LastModifiedTimeAsc : EmailSortStyle.SentTimeAsc,
            descValue: page.id === EmailStatus.Draft ? EmailSortStyle.LastModifiedTimeDesc : EmailSortStyle.SentTimeDesc,
            clickable: true
        });

        header.push({
            id: "author",
            label: page.id === EmailStatus.Draft ? "Last modified by" : "Published by",
            ascValue: page.id === EmailStatus.Draft ? EmailSortStyle.LastModifiedByAsc : EmailSortStyle.SentByAsc,
            descValue: page.id === EmailStatus.Draft ? EmailSortStyle.LastModifiedByDesc : EmailSortStyle.SentByDesc,
            clickable: true
        });

        header.push({
            id: "fromAddress",
            label: "From address",
            ascValue: EmailSortStyle.FromSenderAsc,
            descValue: EmailSortStyle.FromSenderDesc,
            clickable: true
        });

        return header;
    }

    if (!show) 
        return <></>;
    else if (showIsLoading) 
        return <div style={{ paddingTop: "60px" }}><Loading /></div>;
    else if (!page.emails?.length) 
        return <ManagementEmptyResults
            searchType={"Emails"} 
            hasFilters={hasFiltersApplied} 
            height={hasFiltersApplied ? "63vh" : "80vh"}
        />
    else 
        return <>
            { hasFiltersApplied && <Typography variant="h2" className="filter-results">Results</Typography> }
            <TableContainer>
                <Table size="medium">
                    <TableHead>
                        <TableRow>
                            {
                                getHeader().map((headerCell) => 
                                    <TableCell
                                        key={headerCell.id}
                                        align={headerCell.id === "title" ? "left" : "center"}
                                        padding={headerCell.id === "title" ? "none" : "normal"}
                                        onClick={() => onClickHeader(headerCell.descValue, headerCell.ascValue)}
                                    >
                                        <div style={{
                                            paddingBottom: filters.sortBy === headerCell.ascValue || filters.sortBy === headerCell.descValue ?
                                                "8px" : "0px", cursor: headerCell.clickable ? "pointer" : "",
                                            marginLeft: headerCell.id === "title" ? 10 : 0
                                        }}
                                        >
                                            {headerCell.label}
                                            {filters.sortBy === headerCell.ascValue && <ArrowUpward htmlColor="#7A7A7A" style={{ position: "relative", top: "8px", paddingLeft: "2px" }} />}
                                            {filters.sortBy === headerCell.descValue && <ArrowDownward htmlColor="#7A7A7A" style={{ position: "relative", top: "8px", paddingLeft: "2px" }} />}
                                        </div>
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            page.emails.map(email => 
                                <TableRow
                                    hover
                                    onClick={() => email.status === "draft" ? handleDraftEmailClick(email) : onPreviewEmail(email)}
                                    tabIndex={-1}
                                    key={email.id}
                                    style={{ cursor: "pointer" }}
                                >
                                    <TableCell component="th" scope="row" padding="none">
                                        <span className="email-subject-row-value">{formatSubject(email.subject)}</span>
                                    </TableCell>
                                    <TableCell>
                                        <EmailOptions 
                                            email={email}
                                            onDownloadEmailActivity={email.status !== "draft" ? (email) => onDownloadEmailActivity(email.id) : undefined}
                                            onEditEmail={email.status === "draft" ? handleDraftEmailClick : undefined}
                                            onDuplicateEmail={onDuplicateEmail}
                                            onPreviewEmail={onPreviewEmail}
                                            onDeleteEmail={email.status === "draft" || email.status === "scheduled" ? onDeleteEmail : undefined}
                                            onUnsendEmail={email.status === "scheduled" ? onUnsendEmail : undefined}
                                        />
                                    </TableCell>
                                    <TableCell align="center">{formatTime(email.status === "draft" ? email.lastModification.modifiedTime : email.sentTime)}</TableCell>
                                    <TableCell align="center">{(email.status === "draft" ? email.lastModification?.modifiedBy.name : email.sentBy?.name) ?? ""}</TableCell>
                                    <TableCell align="center" className="email-from-sender-table-cell">{email.fromSender ? `${email.fromSender.name}, ${email.fromSender.email}` : ""}</TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Paging
                currentPage={page.currentPage}
                items={page.emails}
                totalItems={page.totalEmails}
                totalPages={page.totalPages}
                onChangePage={(page, rowsPerPage) => onChangePage(page, rowsPerPage, filters)}
                rowsCookieName={ROWS_PER_PAGE_COOKIE_NAMES.EMAILS}
                defaultRowsPerPage={25}
            />       
        </>;
};

export default EmailsTable;
