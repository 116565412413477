import * as React from "react";
import { connect, ConnectedProps } from "react-redux";

import { GlobalApplicationState } from "globalApplicationState";
import { EventFeedItem } from "../../../../../models";
import Event from "./Event";

import { FeedButtons } from "modules/common/components/feed/feedButtons";
import { ILoadMoreButton } from "modules/common/components/feed/loadMoreButton";

import "../../../../../styles/attendance.sass";
import "../../../styles/visualCard.sass";
import { CardType } from "../../../models";

const VisualCard: React.FunctionComponent<PropsWithRedux> = props => {
    const events = props.events ?? props.eventsFeed;

    return (
        <div className={`card ${props.cardType ?? ""}`}>
            {
                events.map(event =>
                    <Event
                        key={event.id}
                        event={event}
                        onEventSelected={props.onEventSelected}
                        hideTopics={props.hideTopics}
                        hideSummary={props.hideSummary}
                        cardCount={props.cardCount}
                        showReadingStatus={props.showReadingStatus}
                    />
                )
            }
            {
                (props.viewAll || props.loadMore?.canLoadMore) &&
                <div className={"event load-tile"}>
                    <FeedButtons
                        inCard
                        viewAll={props.viewAll ? { action: props.viewAll } : undefined}
                        loadMore={props.loadMore}
                    />
                </div>
            }
        </div>
    );
};

interface IVisualCard {
    onEventSelected: (eventId: string) => void;
    events?: EventFeedItem[];
    loadMore?: ILoadMoreButton
    viewAll?: () => void;
    hideTopics?: boolean;
    hideSummary?: boolean;
    showReadingStatus?: boolean;
    cardType?: CardType;
    cardCount?: number;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: IVisualCard) => ({
        ...ownProps,
        eventsFeed: state.events.eventsFeed.eventsFeed
    })
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export default connector(VisualCard);