import React from "react";

import ImageGalleryDialog from "./dialogs/imageGalleryDialog";
import IntenseEmphasis from "./dialogs/intenseEmphasis";
import { GlobalApplicationState } from "globalApplicationState";
import { connect, ConnectedProps } from "react-redux";
import * as actions from 'modules/posts/actionCreator';
import * as galleryActions from "modules/gallery/actionCreator";
import { AuthoringTinyEditor } from "./authoringTinyMce";
import { useTinyMceAuthoringLogic } from "modules/common/hooks/useTinyMceAuthoringLogic";
import * as adminLayoutActions from "modules/adminLayout/actionCreator";
import { ICustomCssModelv1 } from "api/files";

import "./styles/body.sass";

/**
 * v1 authoring component
 */

const BodyComponent: React.FunctionComponent<PropsWithRedux> = ({
    body,
    defaultLcid,
    lcid,
    livePreview,
    floatingToolbar,
    customCss,
    changedSinceSaved,
    onChange,
    grabMentionUsers,
    getImage,
    setMainStyle,
    setHeaderStyle
}) => {
    const {
        editorRef,
        intenseEmphasisDialogOpen,
        inlineImageDialogOpen,
        onShowInlineImageDialog,
        onShowIntenseEmphasisDialog,
        onApplyInlineImage,
        onApplyIntenseEmphasis,
        onHideInlineImageDialog,
        onHideIntenseEmphasisDialog
    } = useTinyMceAuthoringLogic(getImage);

    const onFullscreen = (isFullscreen?: boolean) => {
        if (isFullscreen) {
            // one more than side nav bar
            setMainStyle({ zIndex: 3 });
            setHeaderStyle({ display: "none" });
        } else {
            setMainStyle(undefined);
            setHeaderStyle(undefined);
        }
    }

    return (
        <div className="multilingual-editor-field">
            <AuthoringTinyEditor
                customCss={customCss}
                value={body}
                activeLcid={lcid}
                defaultLcid={defaultLcid}
                livePreview={livePreview}
                floatingToolbar={floatingToolbar}
                editorRef={editorRef}
                changedSinceSaved={changedSinceSaved}
                onShowInlineImageDialog={onShowInlineImageDialog}
                onShowIntenseEmphasisDialog={onShowIntenseEmphasisDialog}
                grabMentionUsers={grabMentionUsers}
                onChange={onChange}
                onFullscreen={onFullscreen}
                initOptions={{
                    max_height: 800,
                    min_height: 350,
                }}
            />
            <ImageGalleryDialog show={inlineImageDialogOpen} allowLink onApply={onApplyInlineImage} onClose={onHideInlineImageDialog} />
            <IntenseEmphasis show={intenseEmphasisDialogOpen} onApply={onApplyIntenseEmphasis} onClose={onHideIntenseEmphasisDialog} />
        </div>
    );
}

interface ComponentProps {
    body: string;
    livePreview?: boolean;
    floatingToolbar?: boolean;
    lcid: string;
    customCss?: ICustomCssModelv1;
    onChange: (body: string) => void;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        defaultLcid: state.settings.tenantSettings.defaultLCID,
        activeLanguage: state.settings.activeLanguageLCID,
        adminLayout:  state.adminLayout,
        changedSinceSaved: state.posts.editor.changedSinceSaved
    }),
    {
        grabMentionUsers: actions.getMentionsUsers,
        getImage: galleryActions.getImage,
        setMainStyle: adminLayoutActions.setMainStyle,
        setHeaderStyle: adminLayoutActions.setHeaderStyle
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;
export const Body = connector(BodyComponent);
