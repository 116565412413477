import React from "react";

import { FeedLayout } from "../../models/common";
import { IEventsFeedConfig } from "../../models/feed";
import { contentHeaderTemplate, ignoreSubscriptionsTemplate, lockedToTopicsTemplate, optionalDataTemplate, stylingTemplate } from "./templates";

import EventFeedSection from "modules/events/components/event-feed/eventFeedSection";

export const EventsFeedBlock: React.FunctionComponent<{ data: IEventsFeedConfig, columnCount: number; }> = props => {
    const [refresh, setRefresh] = React.useState<boolean>(false);
    const {
        max_results,
        locked_to_topics,
        event_types,
        ignore_subscriptions
    } = props.data;

    React.useEffect(() => {
        setRefresh(true);
    }, [max_results, locked_to_topics, event_types, ignore_subscriptions]);

    // calculates card count based on the type of column
    const cardCount = -props.columnCount + 4;

    return (
        <EventFeedSection
            layout={props.data.layout}
            maxResults={Number.parseInt(props.data.max_results)}
            cardCount={cardCount}
            lockedToTopics={locked_to_topics}
            eventTypes={event_types}
            optionalContent={{
                ignoreSubscriptions: props.data.ignore_subscriptions,
                hideLoadMore: props.data.hide_load_more,
                hideViewAll: props.data.hide_view_all,
                hideSummary: props.data.hide_summary,
                hideTopics: props.data.hide_topics,
            }}
            refresh={refresh}
            resetRefresh={() => setRefresh(false)}
        />
    );
};

const eventTypeTemplate = {
    name: "event_types",
    label: "Lock to specific event types",
    description: "The component will only load events with event types selected. If none are selected, all types are loaded.",
    buttonLabel: "Add event types",
    component: "select-items",
    options: [
        { value: "standard", label: "Standard RSVP" },
        { value: "mandatory", label: "Mandatory RSVP" },
        { value: "informational", label: "Informational" }
    ]
};

export const EVENT_FEED_TEMPLATE = {
    key: "events-feed-block",
    label: "Events Feed",
    defaultItem: {
        layout: FeedLayout.HorizontalBand,
        max_results: 6,
        locked_to_topics: [],
        event_types: [],
        ignore_subscriptions: false,
        hide_topics: false,
        hide_summary: false,
        hide_load_more: false,
        hide_view_all: false,
    },
    fields: [
        contentHeaderTemplate,
        ...ignoreSubscriptionsTemplate,
        lockedToTopicsTemplate("event"),
        eventTypeTemplate,
        optionalDataTemplate.header,
        ...optionalDataTemplate.eventsData,
        ...optionalDataTemplate.buttons,
        ...Object.values(stylingTemplate("event"))
    ]
};