import { Action } from "redux";
import * as Actions from "./actions";
import { NavigationPage, PortalPagesListingPage, PortalPagesState } from "./models";

export const defaultListingPage: PortalPagesListingPage = {
  id: "",
  currentPage: 0,
  portalPages: [],
  totalPortalPages: 0,
  totalPages: 0,
  isFetching: false
};

export const defaultNavigationPage: NavigationPage = {
  navigationItems: [],
  isFetching: false,
  shouldFetch: true
};

export const defaultPortalPage = {
  navigation: {
    enabled: true
  },
  header: {
    banner_text: "",
    enabled: true,
    image_alignment: "bottom",
    background_color: "#FFFFFF",
    overlay_color: "#DDE1E5",
    overlay_opacity: 85
  }
};

const defaultState: PortalPagesState = {
  all: { ...defaultListingPage, id: "all" },
  enabled: { ...defaultListingPage, id: "enabled" },
  drafts: { ...defaultListingPage, id: "drafts" },
  disabled: { ...defaultListingPage, id: "disabled" },
  navigation: { ...defaultNavigationPage },
  navigationItems: { ...defaultNavigationPage },
  preview: undefined,
  errorMessage: "",
  isDeleting: false,
  isFetching: false,
  isInitialLoad: true,
  isSaving: false,
  isUploading: false,
  shouldFetch: false,
  shouldUpdateDraft: false,
  showBackToPortalPages: false,
  showGlobalComponents: false,
  showGoToNavigationItems: false,
  showModifyPortalPageDetails: false,
  showModifyPortalPageTitle: false,
  showNewNavigationItem: false,
  showNewPortalPage: false,
  showPreview: false,
  showPublishChanges: false,
  customCss: {},
  fetchingCss: false,
};

const actionHandler: { [actionType: string]: (state: PortalPagesState, action: any) => PortalPagesState } = {

  [Actions.GET_DRAFT]: (state: PortalPagesState, action: Actions.GetDraft) => {
    return {
      ...state,
      isFetching: true
    };
  },

  [Actions.GET_DRAFT_COMPLETE]: (state: PortalPagesState, action: Actions.GetDraftComplete) => {
    return {
      ...state,
      isFetching: false,
      shouldUpdateDraft: !action.succeeded
    };
  },


  [Actions.GET_PORTAL_PAGES]: (state: PortalPagesState, action: Actions.GetPortalPages) => {
    return {
      ...state,
      all: {
        ...state.all,
        isFetching: true
      }
    };
  },

  [Actions.GET_PORTAL_PAGES_COMPLETE]: (state: PortalPagesState, action: Actions.GetPortalPagesComplete) => {
    return {
      ...state,
      all: {
        ...state.all,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },

  [Actions.GET_DISABLED_PORTAL_PAGES]: (state: PortalPagesState, action: Actions.GetDisabledPortalPagesComplete) => {
    return {
      ...state,
      disabled: {
        ...state.disabled,
        isFetching: true
      }
    };
  },

  [Actions.GET_DISABLED_PORTAL_PAGES_COMPLETE]: (state: PortalPagesState, action: Actions.GetDisabledPortalPagesComplete) => {
    return {
      ...state,
      disabled: {
        ...state.disabled,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },

  [Actions.GET_DRAFT_PORTAL_PAGES]: (state: PortalPagesState, action: Actions.GetDraftPortalPages) => {
    return {
      ...state,
      drafts: {
        ...state.drafts,
        isFetching: true
      }
    };
  },

  [Actions.GET_DRAFT_PORTAL_PAGES_COMPLETE]: (state: PortalPagesState, action: Actions.GetDraftPortalPagesComplete) => {
    return {
      ...state,
      drafts: {
        ...state.drafts,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },

  [Actions.GET_ENABLED_PORTAL_PAGES]: (state: PortalPagesState, action: Actions.GetEnabledPortalPages) => {
    return {
      ...state,
      enabled: {
        ...state.enabled,
        isFetching: true
      }
    };
  },

  [Actions.GET_ENABLED_PORTAL_PAGES_COMPLETE]: (state: PortalPagesState, action: Actions.GetEnabledPortalPagesComplete) => {
    return {
      ...state,
      enabled: {
        ...state.enabled,
        ...action.page,
        isFetching: false
      },
      isInitialLoad: false
    };
  },

  [Actions.GET_NAVIGATION]: (state: PortalPagesState, action: Actions.GetNavigation) => {
    return {
      ...state,
      navigation: {
        ...state.navigation,
        isFetching: true
      }
    };
  },

  [Actions.GET_NAVIGATION_COMPLETE]: (state: PortalPagesState, action: Actions.GetNavigationComplete) => {
    return {
      ...state,
      navigation: {
        navigationItems: action.navigationItems,
        isFetching: false,
        shouldFetch: false
      },
      errorMessage: action.succeeded ? "" : "Unable to load navigation items"
    };
  },

  [Actions.GET_NAVIGATION_ITEMS]: (state: PortalPagesState, action: Actions.GetNavigationItems) => {
    return {
      ...state,
      navigationItems: {
        ...state.navigationItems,
        isFetching: true
      }
    };
  },

  [Actions.GET_NAVIGATION_ITEMS_COMPLETE]: (state: PortalPagesState, action: Actions.GetNavigationItemsComplete) => {
    return {
      ...state,
      navigationItems: {
        navigationItems: action.navigationItems,
        isFetching: false,
        shouldFetch: false
      },
      errorMessage: action.succeeded ? "" : "Unable to load navigation items"
    };
  },

  [Actions.GET_NAVIGATION_PORTAL_PAGES_COMPLETE]: (state: PortalPagesState, action: Actions.GetNavigationPortalPagesComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to load pages"
    };
  },


  [Actions.HIDE_BACK_TO_PORTAL_PAGES]: (state: PortalPagesState, action: Actions.HideBackToPortalPages) => {
    return {
      ...state,
      showBackToPortalPages: false
    };
  },

  [Actions.HIDE_GLOBAL_COMPONENTS]: (state: PortalPagesState, action: Actions.HideGlobalComponents) => {
    return {
      ...state,
      showGlobalComponents: false
    };
  },

  [Actions.HIDE_GO_TO_NAVIGATION_ITEMS]: (state: PortalPagesState, action: Actions.HideGoToNavigationItems) => {
    return {
      ...state,
      showGoToNavigationItems: false
    };
  },

  [Actions.HIDE_NEW_PORTAL_PAGE]: (state: PortalPagesState, action: Actions.HideNewPortalPage) => {
    return {
      ...state,
      showNewPortalPage: false
    };
  },

  [Actions.HIDE_PREVIEW]: (state: PortalPagesState, action: Actions.HidePreview) => {
    return {
      ...state,
      showPreview: false
    };
  },

  [Actions.HIDE_PUBLISH_CHANGES]: (state: PortalPagesState, action: Actions.HidePublishChanges) => {
    return {
      ...state,
      showPublishChanges: false
    };
  },

  [Actions.SHOW_BACK_TO_PORTAL_PAGES]: (state: PortalPagesState, action: Actions.ShowBackToPortalPages) => {
    return {
      ...state,
      showBackToPortalPages: true
    };
  },

  [Actions.SHOW_GLOBAL_COMPONENTS]: (state: PortalPagesState, action: Actions.ShowGlobalComponents) => {
    return {
      ...state,
      showGlobalComponents: true
    };
  },

  [Actions.SHOW_GO_TO_NAVIGATION_ITEMS]: (state: PortalPagesState, action: Actions.ShowGoToNavigationItems) => {
    return {
      ...state,
      showGoToNavigationItems: true
    };
  },

  [Actions.SHOW_NEW_PORTAL_PAGE]: (state: PortalPagesState, action: Actions.ShowNewPortalPage) => {
    return {
      ...state,
      preview: undefined,
      showNewPortalPage: true
    };
  },

  [Actions.SHOW_PREVIEW]: (state: PortalPagesState, action: Actions.ShowPreview) => {
    return {
      ...state,
      preview: action.page,
      showPreview: true
    };
  },

  [Actions.SHOW_PUBLISH_CHANGES]: (state: PortalPagesState, action: Actions.ShowPublishChanges) => {
    return {
      ...state,
      showPublishChanges: true
    };
  },


  [Actions.CREATE_NEW_CHILD_NAVIGATION_ITEM]: (state: PortalPagesState, action: Actions.CreateNewChildNavigationItem) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.CREATE_NEW_CHILD_NAVIGATION_ITEM_COMPLETE]: (state: PortalPagesState, action: Actions.CreateNewChildNavigationItemComplete) => {
    return {
      ...state,
      navigation: {
        ...state.navigation,
        shouldFetch: action.succeeded
      },
      navigationItems: {
        ...state.navigationItems,
        shouldFetch: action.succeeded
      },
      errorMessage: action.succeeded ? "" : "Unable to create new child navigation item",
      isSaving: false
    };
  },

  [Actions.CREATE_NEW_DRAFT]: (state: PortalPagesState, action: Actions.CreateNewDraft) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.CREATE_NEW_DRAFT_COMPLETE]: (state: PortalPagesState, action: Actions.CreateNewDraftComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to create new page",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.CREATE_NEW_NAVIGATION_ITEM]: (state: PortalPagesState, action: Actions.CreateNewNavigationItem) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.CREATE_NEW_NAVIGATION_ITEM_COMPLETE]: (state: PortalPagesState, action: Actions.CreateNewNavigationItemComplete) => {
    return {
      ...state,
      navigation: {
        ...state.navigation,
        shouldFetch: action.succeeded
      },
      navigationItems: {
        ...state.navigationItems,
        shouldFetch: action.succeeded
      },
      errorMessage: action.succeeded ? "" : "Unable to create new navigation item",
      isSaving: false
    };
  },
  
  [Actions.DELETE_DRAFT]: (state: PortalPagesState, action: Actions.DeleteDraft) => {
    return {
      ...state,
      isDeleting: true
    };
  },
  
  [Actions.DELETE_DRAFT_COMPLETE]: (state: PortalPagesState, action: Actions.DeleteDraftComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to delete draft",
      isDeleting: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.DELETE_NAVIGATION_ITEM]: (state: PortalPagesState, action: Actions.DeleteNavigationItem) => {
    return {
      ...state,
      isDeleting: true
    };
  },

  [Actions.DELETE_NAVIGATION_ITEM_COMPLETE]: (state: PortalPagesState, action: Actions.DeleteNavigationItemComplete) => {
    return {
      ...state,
      navigation: {
        ...state.navigation,
        shouldFetch: action.succeeded
      },
      navigationItems: {
        ...state.navigationItems,
        shouldFetch: action.succeeded
      },
      errorMessage: action.succeeded ? "" : "Unable to delete navigation item",
      isDeleting: false
    };
  },

  [Actions.DELETE_PORTAL_PAGE]: (state: PortalPagesState, action: Actions.DeletePortalPage) => {
    return {
      ...state,
      isDeleting: true
    };
  },

  [Actions.DELETE_PORTAL_PAGE_COMPLETE]: (state: PortalPagesState, action: Actions.DeletePortalPageComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to delete page",
      isDeleting: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.DELETE_PORTAL_PAGES]: (state: PortalPagesState, action: Actions.DeletePortalPages) => {
    return {
      ...state,
      isDeleting: true
    };
  },

  [Actions.DELETE_PORTAL_PAGES_COMPLETE]: (state: PortalPagesState, action: Actions.DeletePortalPagesComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to delete pages",
      isDeleting: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.DISABLE_PORTAL_PAGE]: (state: PortalPagesState, action: Actions.DisablePortalPage) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.DISABLE_PORTAL_PAGE_COMPLETE]: (state: PortalPagesState, action: Actions.DisablePortalPageComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to disable page",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.ENABLE_PORTAL_PAGE]: (state: PortalPagesState, action: Actions.EnablePortalPage) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.ENABLE_PORTAL_PAGE_COMPLETE]: (state: PortalPagesState, action: Actions.EnablePortalPageComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to enable page",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.MODIFY_DRAFT_DETAILS]: (state: PortalPagesState, action: Actions.ModifyDraftDetails) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.MODIFY_DRAFT_DETAILS_COMPLETE]: (state: PortalPagesState, action: Actions.ModifyDraftDetailsComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to modify page details",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.MODIFY_DRAFT_TITLE]: (state: PortalPagesState, action: Actions.ModifyDraftTitle) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.MODIFY_DRAFT_TITLE_COMPLETE]: (state: PortalPagesState, action: Actions.ModifyDraftTitleComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to modify page title",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.MODIFY_PORTAL_PAGE_DETAILS]: (state: PortalPagesState, action: Actions.ModifyPortalPageDetails) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.MODIFY_PORTAL_PAGE_DETAILS_COMPLETE]: (state: PortalPagesState, action: Actions.ModifyPortalPageDetailsComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to modify page details",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.MODIFY_PORTAL_PAGE_TITLE]: (state: PortalPagesState, action: Actions.ModifyPortalPageTitle) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.MODIFY_PORTAL_PAGE_TITLE_COMPLETE]: (state: PortalPagesState, action: Actions.ModifyPortalPageTitleComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to modify page title",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.PUBLISH_DRAFT]: (state: PortalPagesState, action: Actions.PublishDraft) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.PUBLISH_DRAFT_COMPLETE]: (state: PortalPagesState, action: Actions.PublishDraftComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to publish draft",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.SAVE_DRAFT]: (state: PortalPagesState, action: Actions.SaveDraft) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.SAVE_DRAFT_COMPLETE]: (state: PortalPagesState, action: Actions.SaveDraftComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to save changes",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.SET_NAVIGATION_ITEM_AS_PARENT]: (state: PortalPagesState, action: Actions.SetNavigationItemAsParent) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.SET_NAVIGATION_ITEM_AS_PARENT_COMPLETE]: (state: PortalPagesState, action: Actions.SetNavigationItemAsParentComplete) => {
    return {
      ...state,
      navigation: {
        ...state.navigation,
        shouldFetch: action.succeeded
      },
      navigationItems: {
        ...state.navigationItems,
        shouldFetch: action.succeeded
      },
      errorMessage: action.succeeded ? "" : "Unable to update navigation item",
      isSaving: false
    };
  },

  [Actions.SET_PORTAL_PAGE_AS_HOME]: (state: PortalPagesState, action: Actions.SetPortalPageAsHome) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.SET_PORTAL_PAGE_AS_HOME_COMPLETE]: (state: PortalPagesState, action: Actions.SetPortalPageAsHomeComplete) => {
    return {
      ...state,
      errorMessage: action.succeeded ? "" : "Unable to set page as homepage",
      isSaving: false,
      shouldFetch: action.succeeded
    };
  },

  [Actions.UPDATE_DRAFT]: (state: PortalPagesState, action: Actions.UpdateDraft) => {
    return {
      ...state,
      shouldUpdateDraft: true
    };
  },

  [Actions.UPDATE_NAVIGATION_ITEM_SEQUENCE]: (state: PortalPagesState, action: Actions.UpdateNavigationItemSequence) => {
    return {
      ...state,
      isSaving: true
    };
  },

  [Actions.UPDATE_NAVIGATION_ITEM_SEQUENCE_COMPLETE]: (state: PortalPagesState, action: Actions.UpdateNavigationItemSequenceComplete) => {
    return {
      ...state,
      navigation: {
        ...state.navigation,
        shouldFetch: action.succeeded
      },
      navigationItems: {
        ...state.navigationItems,
        shouldFetch: action.succeeded
      },
      errorMessage: action.succeeded ? "" : "Unable to update navigation item order",
      isSaving: false
    };
  },


  [Actions.CHANGED_SINCE_SAVED]: (state: PortalPagesState, action: Actions.ChangedSinceSaved) => {
    return {
      ...state,
      changedSinceSaved: true
    };
  },

  [Actions.CLEAR_CHANGED_SINCE_SAVED]: (state: PortalPagesState, action: Actions.ChangedSinceSaved) => {
    return {
      ...state,
      changedSinceSaved: false
    };
  },

  [Actions.CLEAR_ERROR_MESSAGE]: (state: PortalPagesState, action: Actions.ClearErrorMessage) => {
    return {
      ...state,
      errorMessage: ""
    };
  },

  [Actions.CLEAR_SHOULD_FETCH]: (state: PortalPagesState, action: Actions.ClearShouldFetch) => {
    return {
      ...state,
      shouldFetch: false
    };
  },

  [Actions.CLEAR_SHOW_PUBLISH_SUCCESS]: (state: PortalPagesState, action: Actions.ClearShowPublishSuccess) => {
    return {
      ...state,
      showPublishSuccess: false
    };
  },

  [Actions.FETCH_CUSTOM_CSS_REQUEST]: (state: PortalPagesState, action: Actions.FetchCustomCssRequest) => {
    return {
      ...state,
      fetchingCss: true
    };
  },

  [Actions.FETCH_CUSTOM_CSS_SUCCESS]: (state: PortalPagesState, action: Actions.FetchCustomCssSuccess) => {
    return {
      ...state,
      customCss: { ...state.customCss, [action.pageId]: action.customCss },
      fetchingCss: false
    };
  },

  [Actions.FETCH_CUSTOM_CSS_FAILURE]: (state: PortalPagesState, action: Actions.FetchCustomCssFailure) => {
    return {
      ...state,
      fetchingCss: false
    };
  },

}

export const reducer = (state: PortalPagesState, action: Action) => {
  const actionHandlerMethod = actionHandler[action.type];
  if (actionHandlerMethod) {
    return actionHandlerMethod(state, action);
  }
  return state || defaultState;
};
