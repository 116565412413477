import { GlobalApplicationState } from "globalApplicationState";
import Bodyv2 from "modules/common/components/authoring/bodyv2";
import FilledInput from "modules/common/components/forms/inputs/filledInput";
import { EMAIL_PERSONALIZATION_TOKENS, IEmail, IPersonalizationToken, MERGE_TAG_PREFIX, MERGE_TAG_SUFFIX } from "modules/emails/models";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import PersonalizationTokenButton from "./personalizationTokenButton";
import { ITranslatedText } from "modules/common/models";

const MAX_SUBJECT_LENGTH = 900;

interface IEmailEditorProps {
    updateEmail: (updatedEmailFields: Partial<IEmail>) => void;
    activeLcid: string;
    onChangeFullscreen?: (isFullscreen?: boolean) => void;
}

const EmailEditor: React.FC<IEmailEditorProps> = ({
    updateEmail,
    activeLcid,
    onChangeFullscreen
 }) => {
    const { email, changedSinceLastSaved } = useSelector((state: GlobalApplicationState) => state.emails.editor);
    const bodyValue = email.body.find(b => b.lcid === activeLcid)?.text;
    const [innerSubjectValue, setInnerSubjectValue] = useState(email.subject);
    const [oldSubjectText, setOldSubjectText] = useState("");
    const [showIcons, setShowIcons] = useState(false);
    const textFieldRef = useRef<HTMLInputElement>(null);
    const iconsDivRef = useRef<HTMLDivElement>(null);
    const tokenMenuPaperRef = useRef<HTMLDivElement>(null);

    // subject is considered blurred if the subject field is not focussed AND the icons related to the subject field are not focussed
    const subjectIsBlurred = (focussedElement: Node | Element | null) => 
        textFieldRef.current && !textFieldRef.current.contains(focussedElement) && // if the focussed element is not inside the subject field
        (iconsDivRef.current ? !iconsDivRef.current.contains(focussedElement) : true) && // if the icons div is active and the focussed element is not inside it
        (tokenMenuPaperRef.current ? !tokenMenuPaperRef.current.contains(focussedElement) : true) // if the token menu is active and the focussed element is not inside it

    // add the mouse event listener to hide the icons when the user clicks outside of the subject field or icons div
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (subjectIsBlurred(event.target as Node)) 
                setShowIcons(false);
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // keep the focus on the subject line after changes (like click-adding personalization tokens)
    useEffect(() => {
        if (document.activeElement !== textFieldRef.current)
            textFieldRef.current?.focus();
    }, [innerSubjectValue]);

    const updateTranslatedTextField = (
        lcid: string, 
        newText: string, 
        translatedText: ITranslatedText[]
    ): ITranslatedText[] => [...translatedText.filter(t => t.lcid !== lcid), { lcid, text: newText }];
    
    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Backspace" || event.key === "Delete") {
            // if cursor is inside a merge tag while deleting, prevent default deletion and delete the full tag
            const startEndDelimiters = getMergeTagStartAndEndDelimiter();
            if (startEndDelimiters) {
                event.preventDefault();
                const currentInnerSubjectValue = innerSubjectValue.find(s => s.lcid === activeLcid);

                if (currentInnerSubjectValue) {
                    const newSubjectText = currentInnerSubjectValue.text.slice(0, startEndDelimiters.startDelimiter) + 
                        currentInnerSubjectValue.text.slice(startEndDelimiters.endDelimiter + 1);
                    setInnerSubjectValueText(newSubjectText);
                }
            }
        }
    };

    const handleClick = () => {
        // if the user tries to put their cursor into our merge tags, move it outside.
        const startEndDelimiters = getMergeTagStartAndEndDelimiter();
        if (startEndDelimiters && textFieldRef.current) {
            textFieldRef.current.setSelectionRange(startEndDelimiters.endDelimiter + 1, startEndDelimiters.endDelimiter + 1);
        }
    }

    /**
     * A method for finding the start and end delimiters of a merge tag / personalization token block
     * Returns undefined if there is none found
     */
    const getMergeTagStartAndEndDelimiter = (): { startDelimiter: number; endDelimiter: number } | undefined => {
        if (textFieldRef.current) {
            const cursorStartPos = textFieldRef.current.selectionStart ?? 0;
            const cursorEndPos = textFieldRef.current.selectionEnd ?? 0;
            const text = textFieldRef.current.value;

            // find the boundaries of the merge tags
            const startDelimiter = text.lastIndexOf(MERGE_TAG_PREFIX, cursorStartPos - 1);
            const endDelimiter = text.indexOf(MERGE_TAG_SUFFIX, cursorEndPos - MERGE_TAG_PREFIX.length);

            // see if the cursor is between merge tags
            return startDelimiter !== -1 && 
                endDelimiter !== -1 && 
                startDelimiter < cursorStartPos && 
                cursorEndPos <= endDelimiter + MERGE_TAG_PREFIX.length &&
                // check that the value within the block is one of our tokens
                EMAIL_PERSONALIZATION_TOKENS.find(token => token.value === text.substring(startDelimiter + MERGE_TAG_PREFIX.length, endDelimiter))
                    ? { startDelimiter, endDelimiter: endDelimiter - 1 + MERGE_TAG_PREFIX.length }
                    : undefined;
        }
    }

    const onBlurSubject = (event: React.FocusEvent<HTMLInputElement>) => {
        // add a slight delay to see if we're focussed on the icons div or the token menu so it doesn't immediately hide
        setTimeout(() => {
                if (subjectIsBlurred(document.activeElement))
                    setShowIcons(false);
            }, 
            10
        );

        let newSubject = event.target.value as string;

        if (newSubject !== oldSubjectText && newSubject.length <= MAX_SUBJECT_LENGTH) {
            setOldSubjectText(newSubject);
            onChangeSubjectText(newSubject);
        }
    }

    const onFocusSubject = (event: React.FocusEvent<HTMLInputElement>) => {
        setShowIcons(true);
        setOldSubjectText(event.target.value as string);
    };

    const onChangeSubjectText = (newSubjectText: string) => {
        const newTranslatedText  = updateTranslatedTextField(activeLcid, newSubjectText, innerSubjectValue);
        onChangeSubject(newTranslatedText);
    }

    const onChangeSubject = (newText: ITranslatedText[]) => {
        updateEmail({ subject: newText });
    }

    const onChangeBodyText = (newBodyText: string) => {
        const newTranslatedText = updateTranslatedTextField(activeLcid, newBodyText, email.body);
        onChangeBody(newTranslatedText);
    }

    const onChangeBody = (newBody: ITranslatedText[]) => updateEmail({ body: newBody });

    const setInnerSubjectValueText = (newSubjectText: string) => {
        if (newSubjectText.length > MAX_SUBJECT_LENGTH)
            return;
        
        const newTranslatedText = updateTranslatedTextField(activeLcid, newSubjectText, innerSubjectValue);
        setInnerSubjectValue(newTranslatedText);
        onChangeSubject(newTranslatedText);
    }

    const onChangeInnerSubjectValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newSubject = e.target.value as string;
        setInnerSubjectValueText(newSubject);
    }

    const onTokenClick = (token: IPersonalizationToken) => {
        const input = textFieldRef.current;
        if (input) {
            const textToInsert = `${MERGE_TAG_PREFIX}${token.value}${MERGE_TAG_SUFFIX}`;

            if (textToInsert.length + input.value.length > MAX_SUBJECT_LENGTH) 
                return;

            const start = input.selectionStart || 0;
            const end = input.selectionEnd || 0;

            // insert the text at the current cursor position
            input.setRangeText(textToInsert, start, end, "end");

            // put the cursor at the end of the inserted text
            input.selectionStart = input.selectionEnd = start + textToInsert.length;

            // trigger onchange
            const event = new Event("change", { bubbles: true });
            input.dispatchEvent(event);
        }
    }
    
    return <>
        <FilledInput
            label={"Subject"}
            onUndo={(newValue: string | undefined) => {
                const subjectVal = newValue || "";
                setInnerSubjectValueText(subjectVal);
            }}
            required
            value={innerSubjectValue.find(s => s.lcid === activeLcid)?.text ?? ""}
            inputProps={{
                placeholder: "Enter subject",
                onBlur: onBlurSubject,
                onChange: onChangeInnerSubjectValue,
                onFocus: onFocusSubject,
                onKeyDown: handleKeyDown,
                onClick: handleClick
            }}
            showIcons={showIcons}
            extraIconButton={
                <PersonalizationTokenButton 
                    personalizationTokens={EMAIL_PERSONALIZATION_TOKENS} 
                    onClick={onTokenClick} 
                    menuPaperRef={tokenMenuPaperRef}
                />
            }
            inputLabelContainerStyle={{ alignItems: "center" }}
            controlled
            inputRef={textFieldRef}
            iconsDivRef={iconsDivRef}
            keepFocusAfterUndo
        />
        <Bodyv2
            value={bodyValue}
            editorOptions={{ 
                min_height: 640,
                verify_html: false
            }}
            onChange={newBody => {
                if (newBody !== bodyValue)
                    onChangeBodyText(newBody)
            }}
            required={false}
            personalizationTokens={EMAIL_PERSONALIZATION_TOKENS}
            maxWidth={800}
            changedSinceSaved={changedSinceLastSaved}
            onFocus={() => {
                setShowIcons(false);
            }}
            onFullscreen={onChangeFullscreen}
            livePreview
            enableEmbeddedMedia={false}
            enablePageEmbed={false}
        />
    </>;
};

export default EmailEditor;
