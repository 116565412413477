import { GlobalApplicationState } from "globalApplicationState";
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";

type UseOpenContentGuidelinesReturnType = {
    openContentGuidelines: boolean;
    setOpenContentGuidelines: (value: boolean) => void;
}

export const useOpenContentGuidelines = (): UseOpenContentGuidelinesReturnType => {
    const [openContentGuidelines, setOpenContentGuidelines] = useState(false);
    const [isFirstOpenGuidelines, setIsFirstOpenGuidelines] = useState(true);

    const { guidelines, isEmpty } = useSelector((state: GlobalApplicationState) => state.guidelines);
    const { hideContentGuidelines } = useSelector((state: GlobalApplicationState) => state.settings.currentUser);
    
    useEffect(() => {
        if (guidelines && !isEmpty && !hideContentGuidelines && isFirstOpenGuidelines) {
            setOpenContentGuidelines(true);
            setIsFirstOpenGuidelines(false);
        }
    }, [guidelines, isEmpty, hideContentGuidelines, isFirstOpenGuidelines]);

    return {
        openContentGuidelines,
        setOpenContentGuidelines
    };
}