import _ from "lodash";
import { SyncType } from "modules/audiences";
import { EventAttemptModel, NotificationEvents, TenantNotificationSettings } from "modules/messaging";
import { Action } from "redux";
import * as Actions from "./actions";
import { Channel, RangeType, reminderForInsertion } from "./components/reminderPopout";

import {
    TenantSettings,
    TenantSettingsReaction,
    TenantSettingsTagGroup,
    TenantSettingsTag,
    SettingsState,
    ReactionTab,
    ExternalPostSource,
} from "./models";

const defaultAttemptModel: EventAttemptModel= {
    blackoutPeriod: 0,
    templaceId: "",
    toggle: false,
    translatedBody: {},
    translatedSubject: {},
    translatedContent: {},
};

const defaultState = {
    fetching: false,
    saving: false,
    resetting: false,
    errorMsg: null,
    clientSettings: {
        androidAppUrl: "",
        bannerColors: [],
        bannerVisibility: {
            showRestrictedBanner: false
        },
        chatEnabled: false,
        commentSystem: {
            enabled: false,
            commentMaxLength: 1000,
            isCommentingEnabledForNewPostsByDefault: false,
            gifSelectionEnabledForComments: false,
            moderationEnabled: false
        },
        defaultLCID: "en-us",
        displayName: "",
        documentsEnabled: false,
        id: "",
        iosAppUrl: "",
        keys: {},
        mobileEventsEnabled: false,
        portalPagesEnabled: false,
        reactions: [],
        reactionSystem: {
            enabled: false,
            isReactingEnabledForNewPostsByDefault: false
        },
        specialtyPostColors: {
            breaking: "",
            compliance: "",
            complianceChecked: "",
            featured: "",
            mandatory: "",
            public: "",
            restricted: ""
        },
        surveysEnabled: false,
        teams: {
            spEventsUrl: "",
            spUrl: "",
            companyPortalUrl: "",
            teamsEventsUrl: "",
            teamsUrl: "",
            companyPortalEventsUrl: ""
        },
        shareUrlsConfig: {
            toggleSpUrl: true,
            toggleTeamsUrl: true,
            toggleCompanyPortalUrl: true,
            spUrlShareLinkName: "",
            teamsUrlShareLinkName: "",
            companyPortalUrlShareLinkName: ""
        },
        theme: {
            color: "#2196f3",
            enabled: false
        },
        translatedContent: {},
    },
    tenantSettings: {
        displayName: "",
        reactions: [],
        reactionsEnabled: false,
        isReactingEnabledForNewPostsByDefault: false,
        canContributorsToggleComments: false,
        canContributorsToggleReactions: false,
        tagGroups: [],
        securityGroups: [],
        commentsEnabled: false,
        isCommentingEnabledForNewPostsByDefault: false,
        gifSelectionEnabledForComments: false,
        commentMaxLength: 1000,
        commentModerationEnabled: false,
        themeEnabled: false,
        themeSettings: null,
        ADGroupSyncEnabled: false,
        groups: [],
        defaultLCID: "en-us",
        timeZone: "Mountain Standard Time",
        canManageEveryoneAudience: false,
        translatedContent: {
            "en-us": {
                breakingPost: "Breaking News",
                breakingBannerDescription: "Breaking News! Share with colleagues, and spread the word.",
                compliancePost: "Compliance",
                complianceText: "I acknowledge that I have read and understand the above information.",
                complianceBannerDescription: "If you agree with the content of this post, comply using the checkbox down below.",
                compliedPost: "Complied",
                compliedBannerDescription: "You have already read and complied to this post.",
                featuredPost: "Featured",
                featuredBannerDescription: "Keep in the loop and get the latest facts with our featured news and updates.",
                mandatoryPost: "Mandatory",
                mandatoryBannerDescription: "You are required to read this post.",
                publicPost: "Public",
                publicBannerDescription: "This is a public post",
                publicBannerDescriptionShareTitle: "Share it through here",
                restrictedContent: "Restricted",
                restrictedBannerDescription: "This post is exclusive to specific audiences."
            },
        },
        mandatoryExpiryTime: 0,
        flaggingSettings: {
            minimumNotificationWait: 30,
        },
        supportEmail: "",
        bannerMessage: null,
        publicAuthAllowed: false,
        keys: {},
        bannerVisibility: {
            showRestrictedBanner: true,
        },
        postBannerColors: [],
        specialtyPostColors: null,
        maxAllowedAudiences: 0,
        reportExpiryDays: 7,
        manageTopicsAudiencesInAdmin: true,
        maxAllowedLanguages: 5,
        surveysEnabled: false,
        chatEnabled: false,
        docLockerEnabled: false,
        documentsEnabled: false,
        postSmartContentEnabledByTenant: false,
        mobileEventsEnabled: false,
        portalPagesEnabled: false,
        postNotificationsEnabled: false,
        totalUserCountForReporting: 0,
        mobilePublishingEnabled: false,
        newsletterEnabled: false,
        invitedExternalUsersEnabled: true,
        resetLocalUserPasswordOnLogin: false,
        showFeatures: {
            audiences: false, //so not shown until tenant settings call finishes
            documentsEnabled: false,
            externalPostSources: false,
            everyoneAudienceEnabled: false,
            portalPagesEnabled: false,
            categoryTagsEnabled: false,
            equalToAnySmartAudienceRuleEnabled: false,
            asyncReportProcessingEnabled: false,
            postSmartContentEnabledForTenant: false,
            tinyMceCustomCssEnabled: true,
            homeScreenConfigEnabled: false,
            emailManagementEnabled: false
        },
        syncConfig: {
            type: "",
            mobileSyncConfig: {
                mobileSyncEnabled: false,
                graphMobileFieldSource: "mobilePhone",
                oktaMobileFieldSource: "mobilePhone"
            },
            syncDetails: {
                organizationId: "",
                portalClientId: ""
            },
            syncLevel: "",
            syncType: "",
            syncAllAdUsers: false
        },
        customerLicense: null,
        externalPostSources: [],
        androidAppUrl: "",
        iosAppUrl: "",
        externalPostPurgeInDays: 0,
        externalPostPurgeEnabled: false,
        mobileThemeColors: null,
        everyoneAudienceEnforcementEnabled: false,
        emailNotificationsEnabled: false,
        smsNotificationsEnabled: false,
        teams: {
            spUrl: "Url not configured",
            teamsUrl: "Url not configured",
            companyPortalUrl: "Url not configured",
            spEventsUrl: "Url not configured",
            teamsEventsUrl: "Url not configured",
            companyPortalEventsUrl: "Url not configured"
        },
        shareUrlsConfig: {
            toggleSpUrl: true,
            toggleTeamsUrl: true,
            toggleCompanyPortalUrl: true,
            spUrlShareLinkName: "SharePoint",
            teamsUrlShareLinkName: "Teams",
            companyPortalUrlShareLinkName: "Portal Link"
        },
        lobbyEnabled: false,
        localAccountsEnabled: false,
        localAccountPrefix: "",
    },
    notificationSettings: {
        defaultEmailBlackoutPeriod: 0,
        defaultSMSBlackoutPeriod: 0,
        defaultEventReminder: 0,
        defaultEventSettings: {
            emailOnPublish: "fallback",
            mobileOnPublish: "none",
            smsOnPublish: "fallback",
            teamsOnPublish: "none",
            reminders: []
        },
        defaultPostReminder: 0,
        defaultPostSettings: {
            emailOnPublish: "none",
            mobileOnPublish: "subscribers",
            smsOnPublish: "none",
            teamsOnPublish: "subscribers",
            reminders: []
        },
        fromEmailAddress: "",
        fromEmailName: "",
        linkPreference: "",
        settings: {
            commentFlaggedEmailAttempt: defaultAttemptModel,
            commentFlaggedTeamsAttempt: defaultAttemptModel,
            consentInviteSMSAttempt : defaultAttemptModel,
            docLockerUpdatedMobileAttempt: defaultAttemptModel,
            eventCancelledEmailAttempt : defaultAttemptModel,
            eventPublishedEmailAttempt : defaultAttemptModel,
            eventPublishedMobileAttempt : defaultAttemptModel,
            eventPublishedSMSAttempt : defaultAttemptModel,
            eventPublishedTeamsAttempt : defaultAttemptModel,
            eventReminderEmailAttempt : defaultAttemptModel,
            eventReminderMobileAttempt : defaultAttemptModel,
            eventReminderSMSAttempt : defaultAttemptModel,
            eventReminderTeamsAttempt : defaultAttemptModel,
            eventRespondedEmailAttempt : defaultAttemptModel,
            eventUpdatedEmailAttempt : defaultAttemptModel,
            massSMSAttempt : defaultAttemptModel,
            postCommentCreatedMobileAttempt : defaultAttemptModel,
            postCommentCreatedTeamsAttempt : defaultAttemptModel,
            postPublishedEmailAttempt : defaultAttemptModel,
            postPublishedMobileAttempt : defaultAttemptModel,
            postPublishedSMSAttempt : defaultAttemptModel,
            postPublishedTeamsAttempt : defaultAttemptModel,
            postReminderEmailAttempt : defaultAttemptModel,
            postReminderMobileAttempt : defaultAttemptModel,
            postReminderSMSAttempt : defaultAttemptModel,
            postReminderTeamsAttempt : defaultAttemptModel,
            userProfileUpdatedEmailAttempt : defaultAttemptModel,
            eventMentionedTeamsAttempt : defaultAttemptModel,
            eventMentionedMobileAttempt : defaultAttemptModel,
            postMentionedTeamsAttempt : defaultAttemptModel,
            postMentionedMobileAttempt : defaultAttemptModel,
            userMentionedTeamsAttempt : defaultAttemptModel,
            userMentionedMobileAttempt : defaultAttemptModel,
            userMentionedEmailAttempt : defaultAttemptModel,
            accessGrantedEmailAttempt : defaultAttemptModel,
            postSubmittedTeamsAttempt : defaultAttemptModel,
            verifyPhoneSMSAttempt : defaultAttemptModel,
            postSubmittedEmailAttempt : defaultAttemptModel,
            eventSubmittedTeamsAttempt : defaultAttemptModel,
            eventSubmittedEmailAttempt : defaultAttemptModel,
        }
    },
    tagSettings: {
        tagGroups: [],
        shouldFetch: true
    },
    tenantAttributes: [],
    applyBlackoutPeriodsTo: [],
    pristineTenantSettingsCopy: null,
    pristineNotificationSettingsCopy: null,
    activeReactionTab: ReactionTab.Enabled,
    activeLanguageLCID: "en-us",
    pristine: true,
    showDisabledItems: false,
    currentUser: {
        email: "",
        firstName: "",
        lastName: "",
        userId: "",
        roles: [],
        canManageSupport: false,
        canManageSubmissions: false,
        pinnedFolders: [],
        cardCount: 3,
        chatEnabled: false,
        commentNotificationSettings: "none",
        gradientColor: "",
        hideReadTime: true,
        hideSocialStats: true,
        hideWhenRead: true,
        isSocialUser: false,
        isSparrowLocalUser: false,
        isWorkAccount: false,
        layout: "",
        preferredLCID: "en-us",
        preferredName: "",
        preferredSMSConsent: null,
        preferredSMSPhone: "",
        sendNotificationsWhenAuthor: false,
        subscribedTags: [],
        localId: "",
        showContentAnalysesCallout: true,
        showContentAnalysesDialog: true,
        showSmartContentHelpCallouts: true,
        showMediaGalleryGridView: true,
        hideContentGuidelines: false,
        hidePublishDialog: false,
        providerName: ""
    },
} as SettingsState;

//TO DO This method could probably be refactored at some point.
//A few thoughts:
//1. It is currently throwing 'Object is possibly 'undefined' errors' even though it is working correctly. (could probably just do obj1 == undefined instead of typeof)
//2. It could probably just check the length of both list of properties for equality first.
//3. Are we ever doing unnecessary saves because are are returning false if both are undefined but an 'equals' if the two objects are null?
function deepValueEqual<T>(obj1: T, obj2: T): boolean {
    if (typeof obj1 == "undefined" || typeof obj2 == "undefined") return false;

    if (obj1 === null || obj2 === null) return obj1 === obj2;

    //Loop through properties in object 1
    for (let p in obj1) {
        //Check property exists on both objects
        if (
            !!(obj1 as any).hasOwnProperty &&
            !!(obj2 as any).hasOwnProperty &&
            (obj1 as any).hasOwnProperty(p) !== (obj2 as any).hasOwnProperty(p)
        )
            return false;

        switch (typeof obj1[p]) {
            //Deep compare objects
            case "object":
                if (!deepValueEqual(obj1[p], obj2[p])) return false;
                break;
            //Compare function code
            case "function":
                break;
            //Compare values
            default:
                if (obj1[p] !== obj2[p]) return false;
        }
    }

    //Check object 2 for any extra properties
    for (let p in obj2) {
        if (typeof obj1[p] == "undefined") return false;
    }
    return true;
}

function updateTenantSettingsState(state: SettingsState, newTenantSettings: TenantSettings): SettingsState {
    return {
        ...state,
        tagSettings: {
            ...state.tagSettings,
            shouldFetch: true
        },
        tenantSettings: newTenantSettings,
        pristine: deepValueEqual(state.pristineTenantSettingsCopy, newTenantSettings),
    };
}

function updateNotificationSettingsState(state: SettingsState, newNotificationSettings: TenantNotificationSettings): SettingsState {
    return {
        ...state,
        notificationSettings: newNotificationSettings,
        pristine: deepValueEqual(state.pristineNotificationSettingsCopy, newNotificationSettings)
    };
}

const actionHandler: { [actionType: string]: (state: SettingsState, action: Action) => SettingsState } = {
    [Actions.FETCH_SETTINGS_CLIENT_CONFIG_INIT]: (state: SettingsState, action: Actions.FetchClientConfigInit) => {
        return {
            ...state,
            fetching: true
        };
    },

    [Actions.FETCH_SETTINGS_CLIENT_CONFIG_COMPLETE]: (state: SettingsState, action: Actions.FetchClientConfigComplete) => {
        if (!action.succeeded) {
            return {
                ...state,
                fetching: false,
                errorMsg: "Couldn't fetch settings"
            } as SettingsState;
        }

        return {
            ...state,
            errorMsg: null,
            fetching: false,
            clientSettings: { ...action.clientSettings }
        } as SettingsState;
    },

    [Actions.FETCH_SETTINGS_TENANT_CONFIG_INIT]: (state: SettingsState, action: Actions.FetchTenantConfigInit) => {
        return {
            ...state,
            fetching: true,
        };
    },
    [Actions.FETCH_SETTINGS_TENANT_CONFIG_COMPLETE]: (state: SettingsState, action: Actions.FetchTenantConfigComplete) => {
        if (!action.succeeded) {
            return {
                ...state,
                fetching: false,
                errorMsg: "Couldn't fetch settings",
            } as SettingsState;
        }

        return {
            ...state,
            errorMsg: null,
            fetching: false,
            tenantSettings: {
                ...action.tenantSettings,
            },
            pristineTenantSettingsCopy: { ...action.tenantSettings },
            pristine: true,
        } as SettingsState;
    },
    [Actions.FETCH_NOTIFICATION_SETTINGS_INIT]: (state: SettingsState, action: Actions.FetchNotificationSettingsInit) => {
        return {
            ...state,
            fetching: true,
        };
    },
    [Actions.FETCH_NOTIFICATION_SETTINGS_COMPLETE]: (state: SettingsState, action: Actions.FetchNotificationSettingsComplete) => {
        var settings = action.notificationSettings.settings;
        var blackoutPeriodList: string[] = [];
        for (const [event, value] of Object.entries(settings)) {
            if (value.blackoutPeriod !== 0) {
                blackoutPeriodList.push(event);
            }
        }

        const notificationClone = JSON.parse(JSON.stringify(action.notificationSettings))
        const dummySubjectSMS = { [state.tenantSettings.defaultLCID]: "" };
        const dummyContent = { [state.tenantSettings.defaultLCID]: { m_Item1: "", m_Item2: "" } }

        //If you are having an error in this section, make sure the backend has an event matching the ones in the messaging/models.ts file!
        for (var event in NotificationEvents) {
            let toCheck = NotificationEvents[event];
            if (event.includes("Mobile")) {
                if (Object.keys(notificationClone.settings[toCheck].translatedContent).length === 0) {
                    notificationClone.settings[toCheck].translatedContent = dummyContent;
                }
            }
            else if (event.includes("Email")) {
                if (Object.keys(notificationClone.settings[toCheck].translatedSubject).length === 0) {
                    notificationClone.settings[toCheck].translatedSubject = dummySubjectSMS;
                }
            }
            else if (event.includes("SMS")) {
                if (Object.keys(notificationClone.settings[toCheck].translatedBody).length === 0) {
                    notificationClone.settings[toCheck].translatedBody = dummySubjectSMS;
                }
            }
        }

        return {
            ...state,
            fetching: false,
            pristineNotificationSettingsCopy: JSON.parse(JSON.stringify(notificationClone)),
            notificationSettings: JSON.parse(JSON.stringify(notificationClone)),
            applyBlackoutPeriodsTo: blackoutPeriodList
        };
    },

    [Actions.FETCH_TAG_SETTINGS_COMPLETE]: (state: SettingsState, action: Actions.FetchTagSettingsComplete) => {
        if (!action.succeeded) {
            return {
                ...state,
                errorMsg: "Couldn't fetch settings"
            } as SettingsState;
        }

        return {
            ...state,
            errorMsg: null,
            tagSettings: {
                tagGroups: action.tagSettings,
                shouldFetch: false
            }
        } as SettingsState;
    },

    [Actions.FETCH_TENANT_ATTRIBUTES_COMPLETE]: (state: SettingsState, action: Actions.FetchTenantAttributesComplete) => {
        return {
            ...state,
            tenantAttributes: action.tenantAttributes
        };
    },

    [Actions.FETCH_USER_TAG_SETTINGS_COMPLETE]: (state: SettingsState, action: Actions.FetchUserTagSettingsComplete) => {
        if (!action.succeeded) {
            return {
                ...state,
                errorMsg: "Couldn't fetch user settings"
            } as SettingsState;
        }

        return {
            ...state,
            errorMsg: null,
            tagSettings: {
                tagGroups: action.tagSettings,
                shouldFetch: false
            }
        } as SettingsState;
    },

    [Actions.RESET_TENANT_SETTINGS_TO_PRISTINE]: (state: SettingsState, _: Actions.ResetTenantSettingsToPristine) => {
        let settings = state.pristineNotificationSettingsCopy!.settings;
        let blackoutPeriodList: string[] = [];
        for (const [event, value] of Object.entries(settings)) {
            if (value.blackoutPeriod !== 0) {
                blackoutPeriodList.push(event);
            }
        }

        const pristineNotificationsCopy = JSON.parse(JSON.stringify(state.pristineNotificationSettingsCopy));

        const newState: SettingsState = {
            ...state,
            applyBlackoutPeriodsTo: blackoutPeriodList,
            tagSettings: {
                ...state.tagSettings,
                shouldFetch: true
            },
            tenantSettings: {
                ...state.pristineTenantSettingsCopy!
            },
            notificationSettings: {
                ...pristineNotificationsCopy
            },
            pristine: true,
            errorMsg: null,
            resetting: true
        };

        return newState;
    },
    [Actions.CHANGE_PRISTINE_STATE]: (state: SettingsState, action: Actions.ChangePristineState) => {
        return {
            ...state,
            pristine: action.newState
        };
    },
    [Actions.FETCH_USER_SETTINGS_INIT]: (state: SettingsState, action: Actions.FetchUserSettingsInit) => {
        return {
            ...state,
            fetching: true,
        };
    },
    [Actions.FETCH_USER_SETTINGS_COMPLETE]: (state: SettingsState, action: Actions.FetchUserSettingsComplete) => {
        return {
            ...state,
            fetching: false,
            currentUser: action.user
        };
    },
    [Actions.UPDATE_USER_SETTINGS]: (state: SettingsState, action: Actions.UpdateUserSettings) => {
        return {
            ...state,
            currentUser: {
                ...state.currentUser!,
                ...action.userSettings
            }
        };
    },
    [Actions.TENANT_DISPLAY_NAME_CHANGED]: (state: SettingsState, action: Actions.TenantDisplayNameChanged) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            displayName: action.displayName,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.COMMENTS_ENABLED_CHANGED]: (state: SettingsState, action: Actions.CommentsEnabledChanged) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            commentsEnabled: action.enabled,
            isCommentingEnabledForNewPostsByDefault: action.enabled ? state.tenantSettings.isCommentingEnabledForNewPostsByDefault : false,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.EXTERNAL_POST_PURGE_CHANGED]: (state: SettingsState, action: Actions.ExternalPostPurgeEnabledChanged) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            externalPostPurgeEnabled: action.enabled
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.EVERYONE_AUDIENCE_ENFORCEMENT_CHANGED]: (state: SettingsState, action: Actions.EveryoneAudienceEnforcementChanged) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            everyoneAudienceEnforcementEnabled: action.enabled,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.SET_MOBILE_SYNC_ENABLED]: (state: SettingsState, action: Actions.SetMobileSyncEnabledAction) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            syncConfig: {
                ...state.tenantSettings.syncConfig,
                mobileSyncConfig: {
                    ...state.tenantSettings.syncConfig.mobileSyncConfig,
                    mobileSyncEnabled: action.enabled
                }
            }
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.SET_SYNC_LEVEL]: (state: SettingsState, action: Actions.SetSyncLevelAction) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            syncConfig: {
                ...state.tenantSettings.syncConfig,
                syncLevel: action.syncLevel,
            }
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.SYNC_ALL_AD_USERS_ENABLED]: (state: SettingsState, action: Actions.SetSyncAllAdUsersEnabledAction) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            syncConfig: {
                ...state.tenantSettings.syncConfig,
                syncAllAdUsers: action.enabled
            }
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.SET_MOBILE_SYNC_NUMBER_SELECTED]: (state: SettingsState, action: Actions.SetMobileSyncNumberSelectedAction) => {
        let syncType = state.tenantSettings.syncConfig.syncType;

        let newTenantSettings = {
            ...state.tenantSettings,
            syncConfig: {
                ...state.tenantSettings.syncConfig,
                mobileSyncConfig: {
                    ...state.tenantSettings.syncConfig.mobileSyncConfig,
                    graphMobileFieldSource: syncType === SyncType.MicrosoftAD ? action.numberType! : '',
                    oktaMobileFieldSource: syncType === SyncType.Okta ? action.numberType! : ''
                }
            }
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.MANAGE_TOPICS_AUDIENCES_ADMIN_CHANGED]: (state: SettingsState, action: Actions.ManageTopicsAudiencesInAdminChanged) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            manageTopicsAudiencesInAdmin: action.enabled,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.IS_COMMENTING_ENABLED_FOR_NEW_POSTS_BY_DEFAULT]: (
        state: SettingsState,
        action: Actions.IsCommentingEnabledForNewPostsByDefaultChanged
    ) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            isCommentingEnabledForNewPostsByDefault: state.tenantSettings.commentsEnabled ? action.enabled : false,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.COMMENT_MODERATION_ENABLED]: (state: SettingsState, action: Actions.CommentModerationEnabled) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            commentModerationEnabled: state.tenantSettings.commentsEnabled ? action.enabled : false,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.GIF_SELECTION_ENABLED]: (state: SettingsState, action: Actions.GifSelectionEnabledForCommentsChanged) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            gifSelectionEnabledForComments: state.tenantSettings.commentsEnabled ? action.enabled : false,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.COMMENTS_MAXLENGTH_CHANGED]: (state: SettingsState, action: Actions.CommentsMaxLengthChanged) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            commentMaxLength: action.maxLength,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.THEME_SETTINGS_CHANGED]: (state: SettingsState, action: Actions.ThemeSettingsChanged) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            themeEnabled: action.enabled,
            themeSettings: { ...action.themeSettings },
        } as TenantSettings;

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.POST_BANNER_COLORS_CHANGED]: (state: SettingsState, action: Actions.PostBannerColorsChanged) => {
        let newBannerColors = [...state.tenantSettings.postBannerColors];
        newBannerColors[action.option] = action.color;

        let newTenantSettings = {
            ...state.tenantSettings,
            postBannerColors: newBannerColors,
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.SPECIALTY_COLORS_CHANGED]: (state: SettingsState, action: Actions.SpecialtyColorsChanged) => {
        let newSpecialtyColors = { ...state.tenantSettings.specialtyPostColors! };
        newSpecialtyColors[action.option] = action.color;

        let newTenantSettings = {
            ...state.tenantSettings,
            specialtyPostColors: newSpecialtyColors,
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.MOBILE_COLORS_CHANGED]: (state: SettingsState, action: Actions.MobileColorsChanged) => {
        let newMobileColors = { ...state.tenantSettings.mobileThemeColors! };
        newMobileColors[action.option] = action.color;

        let newTenantSettings = {
            ...state.tenantSettings,
            mobileThemeColors: newMobileColors,
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.SET_EXTERNAL_AUTH_ENABLED]: (state: SettingsState, action: Actions.SetExternalAuthEnabled) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            externalAuthProvidersEnabled: action.enabled,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.REACTIONS_ENABLED]: (state: SettingsState, action: Actions.SetExternalAuthEnabled) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            reactionsEnabled: action.enabled
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.TOGGLE_SHARE_URL]: (state: SettingsState, action: Actions.ToggleShareUrlUrl) => {
        let newToggleUrls = { ...state.tenantSettings.shareUrlsConfig! };
        newToggleUrls[action.shareToggleEvent] = action.enabled;

        let newTenantSettings = {
            ...state.tenantSettings,
            shareUrlsConfig: newToggleUrls
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.SET_SHARE_NAME]: (state: SettingsState, action: Actions.SetShareName) => {
        let newShareNames = { ...state.tenantSettings.shareUrlsConfig! };
        newShareNames[action.shareEvent] = action.name;

        let newTenantSettings = {
            ...state.tenantSettings,
            shareUrlsConfig: newShareNames
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.SET_SHARE_URL]: (state: SettingsState, action: Actions.SetShareUrl) => {
        let newShareUrls = { ...state.tenantSettings.teams! };
        newShareUrls[action.shareEvent] = action.url;

        let newTenantSettings = {
            ...state.tenantSettings,
            teams: newShareUrls
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.REACTION_ENABLED_CHANGED]: (state: SettingsState, action: Actions.ReactionEnabledChanged) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            reactions: sortReactions(
                state.tenantSettings.reactions.map((el) => {
                    if (el.id === action.id) {
                        return {
                            ...el,
                            enabled: action.enabled,
                        };
                    }
                    return el;
                })
            ),
        };
        newTenantSettings.reactionsEnabled = false;
        if (newTenantSettings.reactions.some(r => r.enabled)) {
            newTenantSettings.reactionsEnabled = true;
        }
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.REACTION_NAME_CHANGED]: (state: SettingsState, action: Actions.ReactionNameChanged) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            reactions: state.tenantSettings.reactions.map((el) => {
                if (el.id === action.id) {
                    return {
                        ...el,
                        name: action.name,
                    };
                }
                return el;
            }),
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.REACTION_REORDER]: (state: SettingsState, action: Actions.ReactionReorder) => {
        const newReactions = state.tenantSettings.reactions.map((reaction, index) => {
            const newReaction = {
                ...reaction,
                sortIndex: index * 100,
            };
            if (reaction.id === action.id) {
                newReaction.sortIndex += 150 * (action.up ? -1 : 1);
            }
            return newReaction;
        });
        let newTenantSettings = {
            ...state.tenantSettings,
            reactions: sortReactions(newReactions),
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.IS_REACTING_ENABLED_FOR_NEW_POSTS_BY_DEFAULT]: (
        state: SettingsState,
        action: Actions.IsReactingEnabledForNewPostsByDefaultChanged
    ) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            isReactingEnabledForNewPostsByDefault: state.tenantSettings.reactionsEnabled ? action.enabled : false,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.RESET_SETTINGS_COMPLETE]: (state: SettingsState, _: Actions.ResetSettingsComplete) => {
        return {
            ...state,
            errorMsg: null,
            resetting: false,
        };
    },
    [Actions.SAVE_TENANT_SETTINGS_INIT]: (state: SettingsState, _: Actions.SaveTenantSettingsInit) => {
        return {
            ...state,
            errorMsg: null,
            saving: true,
        };
    },
    [Actions.SAVE_SETTINGS_COMPLETE]: (state: SettingsState, _: Actions.SaveSettingsComplete) => {
        return {
            ...state,
            errorMsg: null,
            saving: false,
        };
    },
    [Actions.SAVE_TENANT_SETTINGS_COMPLETE]: (state: SettingsState, action: Actions.SaveTenantSettingsComplete) => {
        if (action.succeeded) {
            const notificationsCopy = JSON.parse(JSON.stringify(action.notificationSettings))

            return {
                ...state,
                saving: false,
                errorMsg: null,
                tenantSettings: { ...action.tenantSettings },
                pristineTenantSettingsCopy: { ...action.tenantSettings },
                pristineNotificationSettingsCopy: notificationsCopy,
                pristine: true,
            } as SettingsState;
        } else {
            return {
                ...state,
                saving: false,
                errorMsg: "Couldn't save settings.",
            };
        }
    },
    [Actions.SET_ACTIVE_REACTION_TAB]: (state: SettingsState, action: Actions.SetActiveReactionTab) => {
        return {
            ...state,
            activeReactionTab: action.activeReactionTab,
        };
    },
    [Actions.SET_ACTIVE_LANGUAGE]: (state: SettingsState, action: Actions.SetActiveLanguage) => {
        return {
            ...state,
            activeLanguageLCID: action.lcid,
        };
    },
    [Actions.SET_TIME_ZONE]: (state: SettingsState, action: Actions.SetTimeZone) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            timeZone: action.timeZone
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.ADD_TAG_GROUP]: (state: SettingsState, action: Actions.AddTagGroup) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            tagGroups: state.tenantSettings.tagGroups.concat([action.tagGroup]),
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.UPDATE_TAG_GROUP]: (state: SettingsState, action: Actions.UpdateTagGroup) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            tagGroups: state.tenantSettings.tagGroups
                .map((tagGroup) => {
                    if (tagGroup.id === action.id) {
                        return {
                            ...tagGroup,
                            name: action.name,
                            disabled: action.disabled,
                            deleted: action.deleted,
                            tags: tagGroup.tags
                                .map((tag) => {
                                    // if tag group was set to disabled
                                    // set all tags in the group to disabled
                                    if (action.disabled) {
                                        return {
                                            ...tag,
                                            disabled: true,
                                        };
                                    }
                                    return tag;
                                })
                                .filter((tag) => {
                                    // we can remove disabled tags that were created during this session
                                    return !(tag.disabled && tag.isNew);
                                }),
                        };
                    }
                    return tagGroup;
                })
                .filter((tagGroup) => {
                    // only completely remove tagGroup if it was created during this session and
                    // no persisted tags were moved to it
                    // otherwise keep it around as disabled
                    return !(
                        tagGroup.disabled &&
                        tagGroup.isNew &&
                        !tagGroup.tags.some((tag) => {
                            return !tag.isNew;
                        })
                    );
                }),
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.ADD_TAG]: (state: SettingsState, action: Actions.AddTag) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            tagGroups: state.tenantSettings.tagGroups.map((tagGroup) => {
                if (tagGroup.id === action.tagGroupId) {
                    return {
                        ...tagGroup,
                        tags: tagGroup.tags.concat([action.tag]),
                    };
                }
                return tagGroup;
            }),
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.UPDATE_TAG]: (state: SettingsState, action: Actions.UpdateTag) => {
        let updatingTagInfo = findTag(action.id, state.tenantSettings.tagGroups);
        let newTenantSettings = {
            ...state.tenantSettings,
            tagGroups: state.tenantSettings.tagGroups.map((tagGroup) => {
                return {
                    ...tagGroup,
                    // enable the tagGroup if it was disabled and a tag inside it was enabled
                    disabled:
                        !!updatingTagInfo && tagGroup.id === updatingTagInfo.tagGroupId && tagGroup.disabled && !action.disabled
                            ? false
                            : tagGroup.disabled,
                    tags: tagGroup.tags
                        .map((tag) => {
                            if (tag.id === action.id) {
                                return {
                                    ...tag,
                                    name: action.name,
                                    mandatory: action.mandatory,
                                    disabled: action.disabled,
                                    deleted: action.deleted,
                                    default: action.defaultValue,
                                    restricted: action.restricted,
                                    restrictedTo: action.restrictedTo,
                                    mandatoryFor: action.mandatoryFor,
                                    defaultFor: action.defaultFor,
                                };
                            }
                            return tag;
                        })
                        .filter((tag) => {
                            // we don't need to keep tags that were created during this session and then deleted
                            return !(tag.disabled && tag.isNew);
                        }),
                };
            }),
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },
    [Actions.MOVE_TAG]: (state: SettingsState, action: Actions.MoveTag) => {
        let tagInfo = findTag(action.tagId, state.tenantSettings.tagGroups);

        let newTenantSettings = {
            ...state.tenantSettings,
            tagGroups: state.tenantSettings.tagGroups.map((tagGroup) => {
                if (tagGroup.id === action.tagGroupId) {
                    return {
                        ...tagGroup,
                        // if we move an active tag into a disabled tagGroup, we enable the tagGroup
                        disabled: tagGroup.disabled && !!tagInfo && !tagInfo.tag.disabled ? false : tagGroup.disabled,
                        // if being moved to same group don't do anything
                        tags: !!tagInfo && tagInfo.tagGroupId !== action.tagGroupId ? tagGroup.tags.concat([tagInfo.tag]) : tagGroup.tags,
                    };
                } else {
                    return {
                        ...tagGroup,
                        tags: tagGroup.tags.filter((tag) => {
                            return tag.id !== action.tagId;
                        }),
                    };
                }
            }),
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_ERROR_MESSAGE]: (state: SettingsState, action: Actions.SetErrorMessage) => {
        return {
            ...state,
            errorMsg: action.msg,
        };
    },

    [Actions.SET_LANGUAGE_SETTINGS]: (state: SettingsState, action: Actions.SetLanguageSettings) => {
        const newtc = { ...state.tenantSettings.translatedContent };
        //remove old languages
        for (let key of Object.keys(newtc)) {
            if (!!action.chosenLanguages && action.chosenLanguages.indexOf(key) < 0) {
                delete newtc[key];
            }
        }

        //add new languages
        if (!!action.chosenLanguages) {
            for (let key of action.chosenLanguages) {
                if (Object.keys(newtc).indexOf(key) < 0) {
                    newtc[key] = {
                        breakingPost: null,
                        breakingBannerDescription: null,
                        compliancePost: null,
                        complianceText: null,
                        complianceBannerDescription: null,
                        compliedPost: null,
                        compliedBannerDescription: null,
                        featuredPost: null,
                        featuredBannerDescription: null,
                        mandatoryPost: null,
                        mandatoryBannerDescription: null,
                        publicPost: null,
                        publicBannerDescription: null,
                        publicBannerDescriptionShareTitle: null,
                        restrictedContent: null,
                        restrictedBannerDescription: null
                    };
                }
            }
        }
        const newTenantSettings = {
            ...state.tenantSettings,
            translatedContent: { ...newtc },
            defaultLCID: action.defaultLanguage ? action.defaultLanguage : state.tenantSettings.defaultLCID,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_TRANSLATED_TEXT]: (state: SettingsState, action: Actions.SetTranslatedText) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            translatedContent: {
                ...state.tenantSettings.translatedContent,
                [action.lcid]: {
                    ...state.tenantSettings.translatedContent[action.lcid],
                    [action.key]: action.text,
                },
            },
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_FLAGGING_NOTIFICATION_WAIT]: (state: SettingsState, action: Actions.SetFlaggingNotificationWait) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            flaggingSettings: {
                minimumNotificationWait: action.value,
            },
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_MANDATORY_EXPIRY_TIME]: (state: SettingsState, action: Actions.SetMandatoryExpiryTime) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            mandatoryExpiryTime: action.days,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_EXTERNAL_POST_PURGE_IN_DAYS]: (state: SettingsState, action: Actions.SetExternalPostPurgeInDays) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            externalPostPurgeInDays: action.days,
        }
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.TOGGLE_MANDATORY_EXPIRY_TIME]: (state: SettingsState, action: Actions.ToggleMandatoryExpiryTime) => {
        const current = state.tenantSettings.mandatoryExpiryTime;
        const currentlyExists = current || (isNaN(current) && typeof current !== "undefined");
        const newTenantSettings = {
            ...state.tenantSettings,
            mandatoryExpiryTime: currentlyExists ? null : 30,
        } as TenantSettings;
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_SUPPORT_EMAIL]: (state: SettingsState, action: Actions.SetSupportEmail) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            supportEmail: action.email,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_SURVEYS_ENABLED]: (state: SettingsState, action: Actions.SetSurveysEnabled) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            surveysEnabled: action.enabled,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_CHAT_ENABLED]: (state: SettingsState, action: Actions.SetChatEnabled) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            chatEnabled: action.enabled,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_DOCUMENTS_ENABLED]: (state: SettingsState, action: Actions.SetDocumentsEnabled) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            documentsEnabled: action.enabled
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_MOBILE_EVENTS_ENABLED]: (state: SettingsState, action: Actions.SetMobileEventsEnabled) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            mobileEventsEnabled: action.enabled,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_MOBILE_PUBLISHING_ENABLED]: (state: SettingsState, action: Actions.SetMobilePublishingEnabled) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            mobilePublishingEnabled: action.enabled,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_PORTAL_PAGES_ENABLED]: (state: SettingsState, action: Actions.SetPortalPagesEnabled) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            portalPagesEnabled: action.enabled
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_SHOW_INVITED_EXTERNAL_USERS_ENABLED]: (state: SettingsState, action: Actions.SetShowInvitedExternalUsersEnabled) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            invitedExternalUsersEnabled: action.enabled,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_LOCAL_PASSWORD_RESET_ENABLED]: (state: SettingsState, action: Actions.SetResetLocalUserPasswordAction) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            resetLocalUserPasswordOnLogin: action.enabled
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.REMOVE_NEW_TAG]: (state: SettingsState, action: Actions.RemoveNewTag) => {
        let newTenantSettings = {
            ...state.tenantSettings,
            tagGroups: state.tenantSettings.tagGroups.map((tagGroup) => {
                if (tagGroup.id === action.groupId) {
                    tagGroup.tags.pop();
                }
                return tagGroup;
            }),
        };

        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.ADD_EXTERNAL_POST_SOURCE_COMPLETE]: (state: SettingsState, action: Actions.AddExternalPostSourceComplete): SettingsState => {
        const newExternalPostSources = [...state.tenantSettings.externalPostSources];
        const newPristineExternalPostSources = [...(state.pristineTenantSettingsCopy?.externalPostSources || [])] as ExternalPostSource[];

        if (action.succeeded) {
            newExternalPostSources.push(action.source);
            newPristineExternalPostSources.push(action.source);
        }
        return updateExternalSourcesState(state, newExternalPostSources, newPristineExternalPostSources);
    },

    [Actions.UPDATE_EXTERNAL_POST_SOURCE_COMPLETE]: (
        state: SettingsState,
        action: Actions.UpdateExternalPostSourceComplete
    ): SettingsState => {
        const newExternalPostSources = [...state.tenantSettings.externalPostSources];
        const newPristineExternalPostSources = [...(state.pristineTenantSettingsCopy?.externalPostSources || [])] as ExternalPostSource[];

        if (action.succeeded && action.source.id) {
            const sourceToUpdateIndex = newExternalPostSources.findIndex((s) => s.id === action.source.id);

            if (sourceToUpdateIndex > -1) {
                newExternalPostSources[sourceToUpdateIndex] = action.source;
            }

            const pristineSourceToUpdateIndex = newPristineExternalPostSources.findIndex((s) => s.id === action.source.id);

            if (pristineSourceToUpdateIndex > -1) {
                newPristineExternalPostSources[pristineSourceToUpdateIndex] = action.source;
            }
        }

        return updateExternalSourcesState(state, newExternalPostSources, newPristineExternalPostSources);
    },

    [Actions.DELETE_EXTERNAL_POST_SOURCE_COMPLETE]: (
        state: SettingsState,
        action: Actions.DeleteExternalPostSourceComplete
    ): SettingsState => {
        const newExternalPostSources = [...state.tenantSettings.externalPostSources];
        const newPristineExternalPostSources = [...(state.pristineTenantSettingsCopy?.externalPostSources || [])] as ExternalPostSource[];

        if (action.succeeded) {
            const sourceToDeleteIndex = newExternalPostSources.findIndex((s) => s.id === action.sourceId);

            if (sourceToDeleteIndex > -1) {
                newExternalPostSources.splice(sourceToDeleteIndex, 1);
            }

            const pristineSourceToDeleteIndex = newPristineExternalPostSources.findIndex((s) => s.id === action.sourceId);

            if (pristineSourceToDeleteIndex > -1) {
                newPristineExternalPostSources.splice(pristineSourceToDeleteIndex, 1);
            }
        }

        return updateExternalSourcesState(state, newExternalPostSources, newPristineExternalPostSources);
    },

    [Actions.SET_EXTERNAL_POST_SOURCE_ENABLED]: (state: SettingsState, action: Actions.SetExternalPostSourceEnabled) => {
        const updatedExternalPostSources = _.cloneDeep(state.tenantSettings.externalPostSources || []);

        const sourceToUpdateIndex = updatedExternalPostSources.findIndex((s) => s.id === action.sourceId);

        if (sourceToUpdateIndex > -1) {
            updatedExternalPostSources[sourceToUpdateIndex].enabled = action.enabled;
        }

        const newTenantSettings = {
            ...state.tenantSettings,
            externalPostSources: updatedExternalPostSources,
        } as TenantSettings;

        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_RESTRICTED_BANNER_ENABLED]: (state: SettingsState, action: Actions.SetRestrictedBannerEnabled) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            bannerVisibility: {
                ...state.tenantSettings.bannerVisibility,
                showRestrictedBanner: action.enabled,
            }
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_SMS_NOTIFICATIONS_ENABLED]: (state: SettingsState, action: Actions.SetSmsNotificationsEnabled) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            smsNotificationsEnabled: action.enabled,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_EMAIL_NOTIFICATIONS_ENABLED]: (state: SettingsState, action: Actions.SetEmailNotificationsEnabled) => {
        const newTenantSettings = {
            ...state.tenantSettings,
            emailNotificationsEnabled: action.enabled,
        };
        return updateTenantSettingsState(state, newTenantSettings);
    },

    [Actions.SET_LINK_PREFERENCE]: (state: SettingsState, action: Actions.SetLinkPreference) => {
        const newSettings = {
            ...state.notificationSettings,
            linkPreference: action.linkPreference
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },

    [Actions.SET_EMAIL_SUBJECT]: (state: SettingsState, action: Actions.SetEmailSubject) => {
        const newSettings = {
            ...state.notificationSettings,
        } as TenantNotificationSettings

        newSettings.settings[action.event].translatedSubject = action.content;

        return updateNotificationSettingsState(state, newSettings);
    },

    [Actions.SET_SMS_BODY]: (state: SettingsState, action: Actions.SetSMSBody) => {
        const newSettings = {
            ...state.notificationSettings,
        } as TenantNotificationSettings

        newSettings.settings[action.event].translatedBody = action.content;

        return updateNotificationSettingsState(state, newSettings);
    },

    [Actions.SET_MOBILE_CONTENT]: (state: SettingsState, action: Actions.SetMobileContent) => {
        const newSettings = {
            ...state.notificationSettings
        } as TenantNotificationSettings

        newSettings.settings[action.event].translatedContent = action.content;

        return updateNotificationSettingsState(state, newSettings);
    },

    [Actions.SET_EMAIL_NAME]: (state: SettingsState, action: Actions.SetEmailName) => {
        const newSettings = {
            ...state.notificationSettings,
            fromEmailName: action.name,
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },

    [Actions.SET_EMAIL_SENDER]: (state: SettingsState, action: Actions.SetEmailSender) => {
        const newEmail = action.name + action.domain;

        const newSettings = {
            ...state.notificationSettings,
            fromEmailAddress: newEmail
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },

    [Actions.SET_APPLY_BLACKOUT_PERIOD]: (state: SettingsState, action: Actions.ApplyBlackoutPeriodAction) => {
        let newBlackoutPeriods;
        if (action.checked) {
            newBlackoutPeriods = [...state.applyBlackoutPeriodsTo, action.teamsEvent, action.mobileEvent, action.emailEvent, action.smsEvent];
        } else {
            newBlackoutPeriods = state.applyBlackoutPeriodsTo.filter((events) => events !== action.smsEvent && events !== action.emailEvent && events !== action.teamsEvent && events !== action.mobileEvent)
        }

        return {
            ...state,
            pristine: false,
            applyBlackoutPeriodsTo: newBlackoutPeriods
        }
    },
    [Actions.SET_ON_PUBLISH]: (state: SettingsState, action: Actions.SetOnPublishAction) => {

        let eventSettings = {
            ...state.notificationSettings[action.location]
        }
        eventSettings[action.platform] = action.value;

        const newSettings = {
            ...state.notificationSettings,
            [action.location]: eventSettings
        }

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_ALL_ON_PUBLISH]: (state: SettingsState, action: Actions.SetAllOnPublishAction) => {
        let eventSettings = {
            ...state.notificationSettings[action.location],
            emailOnPublish: action.value,
            smsOnPublish: action.value,
            teamsOnPublish: action.value,
            mobileOnPublish: action.value
        }

        const newSettings = {
            ...state.notificationSettings,
            [action.location]: eventSettings
        }

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_REMINDERS]: (state: SettingsState, action: Actions.SetRemindersAction) => {
        let newArray = new Array<reminderForInsertion>()

        action.reminders.forEach((e, index) => {
            e.delay = e.daysSelected ? e.displayDelay * 24 : e.displayDelay
            if (newArray.filter(r => r.delay === e.delay).length === 0) {

                if (e.inherit) {
                    let toAdd = {
                        delay: e.delay,
                        channel: Channel.Inherit,
                        range: RangeType.Subscribers
                    } as reminderForInsertion
                    newArray.push(toAdd);
                }

                else {
                    if (e.sms) {
                        let toAdd = {
                            delay: e.delay,
                            channel: Channel.SMS,
                            range: e.smsRange
                        }
                        newArray.push(toAdd);
                    }
                    if (e.email) {
                        let toAdd = {
                            delay: e.delay,
                            channel: Channel.Email,
                            range: e.emailRange
                        }
                        newArray.push(toAdd);
                    }
                    if (e.teams) {
                        let toAdd = {
                            delay: e.delay,
                            channel: Channel.Teams,
                            range: RangeType.Subscribers
                        }
                        newArray.push(toAdd);
                    }
                    if (e.mobile) {
                        let toAdd = {
                            delay: e.delay,
                            channel: Channel.Mobile,
                            range: RangeType.Subscribers
                        }
                        newArray.push(toAdd);
                    }
                }
            }
        })

        const newSettings = state.notificationSettings;
        newSettings[action.location].reminders = newArray;

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_ENABLE_ALL_CHANNELS]: (state: SettingsState, action: Actions.EnableAllChannelsAction) => {

        const newSettings = {
            ...state.notificationSettings,
            settings: {
                ...state.notificationSettings.settings,
                [action.teamsEvent]: {
                    ...state.notificationSettings.settings[action.teamsEvent],
                    toggle: true
                },
                [action.mobileEvent]: {
                    ...state.notificationSettings.settings[action.mobileEvent],
                    toggle: true
                },
                [action.emailEvent]: {
                    ...state.notificationSettings.settings[action.emailEvent],
                    toggle: true
                },
                [action.smsEvent]: {
                    ...state.notificationSettings.settings[action.smsEvent],
                    toggle: true
                }
            }
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_ENABLE_SELECTED_CHANNELS]: (state: SettingsState, action: Actions.EnableSelectedChannelsAction) => {

        const newSettings = {
            ...state.notificationSettings,
            settings: {
                ...state.notificationSettings.settings,
                [action.notificationEvent]: {
                    ...state.notificationSettings.settings[action.notificationEvent],
                    toggle: action.checked
                },

            }
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.TOGGLE_NOTIFICATION_EVENT_ACTION]: (state: SettingsState, action: Actions.ToggleNotificationEventAction) => {

        var toggle;
        if (state.notificationSettings.settings[action.smsEvent].toggle || state.notificationSettings.settings[action.emailEvent].toggle
            || state.notificationSettings.settings[action.teamsEvent].toggle || state.notificationSettings.settings[action.mobileEvent].toggle) {
            toggle = false
        } else {
            toggle = true
        }
        const newSettings = {
            ...state.notificationSettings,
            settings: {
                ...state.notificationSettings.settings,
                [action.emailEvent]: {
                    ...state.notificationSettings.settings[action.emailEvent],
                    toggle: toggle
                },
                [action.smsEvent]: {
                    ...state.notificationSettings.settings[action.smsEvent],
                    toggle: toggle
                },
                [action.teamsEvent]: {
                    ...state.notificationSettings.settings[action.teamsEvent],
                    toggle: toggle
                },
                [action.mobileEvent]: {
                    ...state.notificationSettings.settings[action.mobileEvent],
                    toggle: toggle
                }
            }
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_DEFAULT_BLACKOUT_FOR_EMAIL]: (state: SettingsState, action: Actions.SetDefaultBlackoutForEmail) => {
        const newSettings = {
            ...state.notificationSettings,
            defaultEmailBlackoutPeriod: action.hours
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_DEFAULT_BLACKOUT_FOR_SMS]: (state: SettingsState, action: Actions.SetDefaultBlackoutForSMS) => {
        const newSettings = {
            ...state.notificationSettings,
            defaultSMSBlackoutPeriod: action.hours
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_DEFAULT_BLACKOUT_FOR_TEAMS]: (state: SettingsState, action: Actions.SetDefaultBlackoutForTeams) => {
        const newSettings = {
            ...state.notificationSettings,
            defaultTeamsBlackoutPeriod: action.hours
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_DEFAULT_BLACKOUT_FOR_MOBILE]: (state: SettingsState, action: Actions.SetDefaultBlackoutForMobile) => {
        const newSettings = {
            ...state.notificationSettings,
            defaultMobileBlackoutPeriod: action.hours
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_DEFAULT_REMINDER_FOR_POSTS]: (state: SettingsState, action: Actions.SetDefaultReminderForPosts) => {
        const numberValue = action.days ? action.hours * 24 : action.hours;

        const newSettings = {
            ...state.notificationSettings,
            defaultPostReminder: numberValue
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_DEFAULT_REMINDERS_FOR_EVENTS]: (state: SettingsState, action: Actions.SetDefaultReminderForEvents) => {
        const numberValue = action.days ? action.hours * 24 : action.hours;

        const newSettings = {
            ...state.notificationSettings,
            defaultEventReminder: numberValue
        } as TenantNotificationSettings

        return updateNotificationSettingsState(state, newSettings);
    },
    [Actions.SET_CONTRIBUTORS_CAN_TOGGLE_COMMENTS_CHANGED]: (state: SettingsState, action: Actions.CanContributorsToggle) => {
        const newSettings = {
            ...state.tenantSettings,
            canContributorsToggleComments: action.enabled
        } as TenantSettings

        return updateTenantSettingsState(state, newSettings);
    },
    [Actions.SET_CONTRIBUTORS_CAN_TOGGLE_REACTIONS_CHANGED]: (state: SettingsState, action: Actions.CanContributorsToggle) => {
        const newSettings = {
            ...state.tenantSettings,
            canContributorsToggleReactions: action.enabled
        } as TenantSettings

        return updateTenantSettingsState(state, newSettings);
    },
    [Actions.SET_POST_SMART_CONTENT_ENABLED_BY_TENANT]: (state: SettingsState, action: Actions.SetPostSmartContentEnabledByTenant) => {
        const newSettings = {
            ...state.tenantSettings,
            postSmartContentEnabledByTenant: action.enabled,
        };

        return updateTenantSettingsState(state, newSettings);
    }
};

function updateExternalSourcesState(
    state: SettingsState,
    newExternalPostSources: ExternalPostSource[],
    newPristineExternalPostSources: ExternalPostSource[]
): SettingsState {
    return {
        ...state,
        tenantSettings: {
            ...state.tenantSettings,
            externalPostSources: newExternalPostSources,
        },
        pristineTenantSettingsCopy: state.pristineTenantSettingsCopy
            ? ({
                ...state.pristineTenantSettingsCopy,
                externalPostSources: newPristineExternalPostSources,
            } as TenantSettings)
            : ({
                externalPostSources: newPristineExternalPostSources,
            } as TenantSettings),
    };
}

function findTag(tagId: string, tagGroups: TenantSettingsTagGroup[]): { tag: TenantSettingsTag; tagGroupId: string } | null {
    for (let i = 0; i < tagGroups.length; i++) {
        const tagGroup = tagGroups[i];
        for (let j = 0; j < tagGroup.tags.length; j++) {
            const tag = tagGroup.tags[j];
            if (tag.id === tagId) {
                return {
                    tag,
                    tagGroupId: tagGroup.id,
                };
            }
        }
    }
    return null;
}

function sortReactions(reactions: TenantSettingsReaction[]): TenantSettingsReaction[] {
    return reactions
        .sort((a, b) => {
            if (a.enabled && !b.enabled) {
                return -1;
            } else if (!a.enabled && b.enabled) {
                return 1;
            }

            if (a.sortIndex < b.sortIndex) {
                return -1;
            } else if (a.sortIndex > b.sortIndex) {
                return 1;
            }
            return 0;
        })
        .map((reaction, index) => {
            // clean up sort indexes
            reaction.sortIndex = index;
            return reaction;
        });
}

export const reducer = (prevState: SettingsState | undefined, action: Action) => {
    const state = prevState || defaultState;

    const actionHandlerMethod = actionHandler[action.type];
    if (actionHandlerMethod) {
        return actionHandlerMethod(state, action);
    }

    return state;
};
