import React, { useState } from "react";
import { Container, SxProps, TextField, TextFieldProps } from "@mui/material";
import Chips from "./chips/chips";

interface ITextFieldError {
    isValueValid: (value: string) => boolean;
    errorHelperText?: React.ReactNode;
}

interface IChipListProps {
    textFieldProps?: TextFieldProps;
    values: string[];
    onRemoveChip: (removedChip: string) => void;
    onAddValidatedChips: (addedChips: string[]) => void; // if errorProps.isValueValid is set, will only run if validation passes.
    helperText?: React.ReactNode;
    errorProps?: ITextFieldError; // set this prop to show error messages and apply input validation
    chipClassName?: string;
    containerSx?: SxProps;
}

const ChipListInput: React.FC<IChipListProps> = ({
    textFieldProps,
    values,
    onRemoveChip,
    onAddValidatedChips,
    helperText,
    errorProps,
    chipClassName,
    containerSx
}) => {
    const [innerInputValue, setInnerInputValue] = useState("");
    const [showError, setShowError] = useState(false);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value !== ",") {
            if (value.split(',').length > 1) {
                handleAndValidateInputValue(value);
            }
            else {
                setInnerInputValue(value);
            }

            if (showError) setShowError(false);
        }
    }

    const handleAndValidateInputValue = (inputValue: string) => {
        let allValues = inputValue.split(',').map(v => v.trim().split(' ')[0]);
        let valuesToAdd: string[] = [];
        let valuesLeft: string[] = [];
        let newInnerInputValue = "";

        if (errorProps) {
            valuesToAdd = allValues.filter(errorProps.isValueValid);
            valuesLeft = allValues.filter(k => !valuesToAdd.includes(k));

            if (valuesToAdd.length === 0 && valuesLeft.length === 1) {
                newInnerInputValue = valuesLeft[0];
                setShowError(true);
            }
        }

        const uniqueValidValues = new Set(valuesToAdd.filter(keyword => !values.includes(keyword)));
        if (uniqueValidValues.size > 0)
            onAddValidatedChips(Array.from(uniqueValidValues));
        
        setInnerInputValue(newInnerInputValue);
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const cleanedInputValue = innerInputValue.trim();

        if (cleanedInputValue === "") return;

        if (event.key === "Enter" || event.key === ",") {
            handleAndValidateInputValue(cleanedInputValue);
        }
    };

    return <>
        <TextField
            onKeyDown={onKeyDown}
            value={innerInputValue}
            onChange={onChange}
            fullWidth
            error={showError}
            helperText={showError ? errorProps?.errorHelperText : helperText}
            {...textFieldProps}
        />
        <Container disableGutters sx={{ ...containerSx }}>
            <Chips 
                chips={
                    values.map((name, idx) => 
                        ({
                            id: `${idx}`,
                            name: name,
                            onDelete: () => onRemoveChip(name)
                        })
                    )
                } 
                hideAll
                chipClassName={chipClassName}
            />
        </Container>
    </>;
};

export default ChipListInput;
