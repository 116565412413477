import React from "react";

import { Checkbox, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

import LockIcon from "@mui/icons-material/Lock";

import { IMultiSelectItem } from "modules/common/models";
import "./../styles/calloutMultiselect.sass";

interface IMultiSelectContent<TItem extends IMultiSelectItem<TItem>> {
    availableOptions: IMultiSelectItem<TItem>[]; // a list of items available, can contain nested children.
    selectedOptions: TItem[];
    onChange: (topics: TItem[]) => void;
    nestingAllowed: boolean;
    disableOptions?: boolean;
}

export const MultiSelectContent = <TItem extends IMultiSelectItem<TItem>,>(props: IMultiSelectContent<TItem>) => {
    const { selectedOptions } = props;
    const onClickItem = (clickedItem: TItem) => {
        const isAlreadySelected = selectedOptions.map(opt => opt.id).includes(clickedItem.id);

        const updatedItemList = isAlreadySelected
            ? selectedOptions.filter(item => item.id !== clickedItem.id)
            : [...selectedOptions, clickedItem]

        props.onChange(updatedItemList);
    };

    const isParentSelected = (parent: TItem) => {
        const selectedIds = selectedOptions.map((selected: TItem) => selected.id);
        return parent.children?.every((item: TItem) => selectedIds.includes(item.id));
    };

    const selectParent = (parent: TItem) => {
        const itemsToAdd = parent.children
            ?.filter(item => !selectedOptions.map(t => t.id).includes(item.id))
            ?? [];

        props.onChange([...selectedOptions, ...itemsToAdd]);
    };

    const deselectParent = (parent: TItem) => {
        const itemsToRemove = parent.children?.map((t: TItem) => t.id);
        const newItems = selectedOptions.filter(currentTag => !itemsToRemove?.includes(currentTag.id));

        props.onChange(newItems);
    };

    const handleParentClick = (tagGroup: TItem) => {
        isParentSelected(tagGroup)
            ? deselectParent(tagGroup)
            : selectParent(tagGroup);
    };

    const getChildItem = (item: TItem) => {
        return (
            <ListItemButton
                key={item.id}
                dense
                onClick={() => !props.disableOptions && onClickItem(item)}
                style={props.nestingAllowed ? { paddingLeft: "40px" } : {}}
                disabled={props.disableOptions}
            >
                <ListItemIcon className="callout-checkbox">
                    <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={!props.disableOptions && !!selectedOptions.find((selectedItem) => selectedItem.id === item.id)}
                        disabled={props.disableOptions}
                    />
                </ListItemIcon>
                <ListItemText
                    primary={item.name}
                    secondary={item.restricted ? <LockIcon fontSize="small" htmlColor="#B72026" /> : undefined}
                    classes={{ dense: "callout-list-item-text-dense" }}
                />
            </ListItemButton>
        );
    };

    const getParentItem = (item: TItem) => {
        if (!item.children || item.children.length <= 0) return;

        return (
            <React.Fragment key={item.id}>
                <ListItemButton
                    onClick={() => handleParentClick(item)}
                    className="select-popover-group-header-clickable"
                >
                    <Checkbox
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        size="small"
                        color="primary"
                        checked={isParentSelected(item)}
                    />
                    {item.name}
                </ListItemButton>

                {item.children.map((child: TItem) => getChildItem(child))}
            </React.Fragment>
        );
    };

    return (
        <React.Fragment>
            {
                props.availableOptions.map((item: TItem) =>
                    props.nestingAllowed
                        ? getParentItem(item)
                        : getChildItem(item))
            }
        </React.Fragment>
    );
};
