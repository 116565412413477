import * as React from 'react';
import { useEffect, useState } from 'react';
import moment from 'moment';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { Button, Checkbox, FormControlLabel, Typography } from '@mui/material';

import * as actions from 'modules/posts/actionCreator';
import * as userActions from "modules/profile/actionCreator";
import * as eventActions from "modules/events/actionCreator";
import { GlobalApplicationState } from 'globalApplicationState';
import TenantLink from 'modules/common/components/tenantLink';
import Loading from '../../loading';

import SuccessfulActionDialog from 'modules/common/components/successfulActionDialog';
import { ContentType } from 'modules/common/models';
import { BACK_EMAIL_TEXT, BACK_EVENT_TEXT, BACK_POST_TEXT } from 'modules/common/components/authoring/constants';
import { emailsSlice } from 'modules/emails/reducer';

import "../../successfulActionDialog.sass";

const PublishedContentDialog: React.FunctionComponent<PropsWithRedux> = (props: PropsWithRedux) => {
    const [autoHide, setAutoHide] = useState(props.currentUser.hidePublishDialog);
    const [isTimedOut, setIsTimedOut] = React.useState(false);
    const [isScheduled, setIsScheduled] = useState<boolean>(false);
    const dispatch = useDispatch();

    const hideEmailSentDialog = () => dispatch({ 
        type: emailsSlice.actions.SET_SHOW_SENT_CONFIRMATION, 
        payload: { 
            sentConfirmation: {
                shouldShowDialog: false,
                expectedSentTime: "",
                emailId: "",
            }
        }
    });

    const TIMEOUT = 8000;
    useEffect(() => {
        const autoHideTimeout = setTimeout(() => setIsTimedOut(true), TIMEOUT);
        return () => clearTimeout(autoHideTimeout);
    }, []);

    useEffect(() => {
        if (props.expectedPublishTime) {
            const isScheduledPost = moment(props.expectedPublishTime)
                .isAfter(moment().add(15, "minutes"));

            setIsScheduled(isScheduledPost);
        }
    }, [props.expectedPublishTime]);

    const onClickOK = async () => {
        switch (props.content) {
            case ContentType.Post:
                props.hideSuccessfulPostPublish();
                break;
            case ContentType.Event:
                props.hideSuccessfulEventPublish();
                break;
            case ContentType.Email:
                hideEmailSentDialog();
                break;
        }
        await updateUserSettings();
    };

    const updateUserSettings = async () => {
        if (props.currentUser.hidePublishDialog === autoHide) return;

        await props.saveUserSettings({ ...props.currentUser, hidePublishDialog : autoHide });
    };

    const getRedirectLink = () => {
        const contentUrl = `${ContentType[props.content].toLowerCase()}s`;
        switch (props.content) {
            case ContentType.Post:
            case ContentType.Event:
                return props.contentId ? `~/${contentUrl}/${props.contentId}` : `~/${contentUrl}`;
            case ContentType.Email:
                return `~/admin/${contentUrl}/edit/new`;
        }
    };

    const getDialogImg = (name: string, alt: string) =>
        <img className="gif-wrapper" alt={alt} src={`/images/dialogs/${name}.gif`} />;

    const getReturnButtonText = () => {
        switch (props.content) {
            case ContentType.Post:
                return BACK_POST_TEXT;
            case ContentType.Event:
                return BACK_EVENT_TEXT;
            case ContentType.Email:
                return BACK_EMAIL_TEXT;
        }
    }

    const getReturnButton = () => props.hideFeedRedirect && props.content !== ContentType.Email
            ? <div>
                <a href={`/${props.tenantId}/admin/events`} rel="noreferrer" className="hyperlink">
                    {getReturnButtonText()}
                </a>
            </div>
            : <Button
                variant="text"
                style={{ width: "55%" }}
                onClick={onClickOK}
            >
                {getReturnButtonText()}
            </Button>;

    const autoHideOption = () => <FormControlLabel
        style={{ fontSize: "14px", paddingTop: "5%" }}
        control={
            <Checkbox
                checked={autoHide}
                onChange={(_, checked: boolean) => setAutoHide(checked)}
                size="small"
            />
        }
        label={<Typography variant="caption">Automatically hide this next time</Typography>}
        labelPlacement="end"
    />;

    const getVideoContentWarning = (): JSX.Element => <React.Fragment>
        {
            props.content === ContentType.Post && props.containsVideoContent &&
            <p>The published post contains video content. As a result this may take a little while to process.</p>
        }
    </React.Fragment>;

    const getOkButtonText = () => {
        switch (props.content) {
            case ContentType.Post:
            case ContentType.Event:
                return `Open ${ContentType[props.content].toLocaleLowerCase()} in portal`;
            case ContentType.Email:
                return "Send new email";
        }
    }

    const getImmediateContent = (): JSX.Element => {
        if (props.hideFeedRedirect) return getVideoContentWarning();

        return props.contentId ?
            <React.Fragment>
                <TenantLink
                    to={getRedirectLink()}
                    onClick={onClickOK}
                    inNewTab={true}>
                    <Button variant="contained" color="primary" style={{ width: "55%" }}>
                        {getOkButtonText()}
                    </Button>
                </TenantLink>

                {getVideoContentWarning()}
            </React.Fragment>
            : <Loading />;
    };

    const getDialogContent = () => isScheduled
        ? <Typography fontSize={14}>
            {getScheduledContentDialog()}
            <br />
            {moment(props.expectedPublishTime).format("dddd, MMMM Do YYYY, h:mm a")}
        </Typography>
        : getImmediateContent();

    const getScheduledContentDialog = () => {
        switch (props.content) {
            case ContentType.Post:
            case ContentType.Event:
                return `This ${ContentType[props.content].toLocaleLowerCase()} will be published on`;
            case ContentType.Email:
                return "Your email will be sent on";
        }
    }

    const getSuccessImageAltText = () => {
        switch (props.content) {
            case ContentType.Post:
            case ContentType.Event:
                return `The ${ContentType[props.content].toLocaleLowerCase()} was successfully published.`;
            case ContentType.Email:
                return "The email was successfully sent.";
        }
    }

    const getPublishedTitle = () => {
        switch (props.content) {
            case ContentType.Post:
            case ContentType.Event:
                return "Successfully published!";
            case ContentType.Email:
                return "Your email is on its way!";
        }
    }

    return (
        <React.Fragment>
            {
                props.currentUser.hidePublishDialog || isTimedOut
                    ? null
                    : <SuccessfulActionDialog
                        closeModal={onClickOK}
                        customImage={isScheduled
                            ? getDialogImg("successScheduled", `The ${ContentType[props.content].toLocaleLowerCase()} was successfully scheduled.`)
                            : getDialogImg("successCheck", getSuccessImageAltText())
                        }
                        displayMessage={getDialogContent()}
                        title={isScheduled ? "Successfully scheduled!" : getPublishedTitle()}
                        afterPublishSuccess={onClickOK}
                    >
                        {(isScheduled || props.contentId || props.hideFeedRedirect) && getReturnButton()}
                        {autoHideOption()}
                    </SuccessfulActionDialog>
            }
        </React.Fragment>
    );
};

interface ComponentProps {
    content: ContentType;
    expectedPublishTime: string;
    hideFeedRedirect: boolean;
    containsVideoContent?: boolean;
    contentId?: string;
}

const connector = connect(
    (state: GlobalApplicationState, ownProps: ComponentProps) => ({
        ...ownProps,
        currentUser: state.settings.currentUser,
        tenantId: state.tenant.id
    }),
    {
        hideSuccessfulPostPublish: actions.hidePublishSuccessfulDialog,
        hideSuccessfulEventPublish: eventActions.clearShowPublishSuccess,
        saveUserSettings: userActions.saveUserSettings,
    }
);
type PropsWithRedux = ConnectedProps<typeof connector>;

export default connector(PublishedContentDialog);