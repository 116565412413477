import { FormControl, MenuItem, Select } from "@mui/material";
import { Address } from "modules/newsletters";
import React from "react";
import { ISimpleUser } from "../models";

interface IAddressSelectProps {
    formControlClassName?: string;
    addresses: Address[];
    selectedFromSender: ISimpleUser;
    onChangeAddress: (address: Address) => void;
}

const AddressSelect: React.FC<IAddressSelectProps> = ({
    formControlClassName,
    addresses,
    selectedFromSender,
    onChangeAddress
}) => {
    const selectedEmailIdx = selectedFromSender
        ? addresses.findIndex(a => a.fromEmailAddress === selectedFromSender.email && a.fromEmailName === selectedFromSender.name) :
        0;

    const truncateText = (text: string, maxLength: number): string => 
        text.length > maxLength 
            ? `${text.substring(0, maxLength - 3)}...`
            : text;

    return <FormControl size="small" className={formControlClassName} fullWidth>
        <Select
            variant="outlined"
            value={selectedEmailIdx}
            onChange={e => {
                const newAddrIdx = e.target.value as number;
                if (newAddrIdx !== selectedEmailIdx && -1 < newAddrIdx && newAddrIdx < addresses.length) {
                    const newSelectedAddress = addresses[newAddrIdx];
                    onChangeAddress(newSelectedAddress);
                }
            }}
        >
            {
                addresses && 
                addresses.map((address, idx) =>
                    <MenuItem value={idx} key={idx}>
                        <div className="email-sender-menu-item-container">
                            <span className="name">{truncateText(address.fromEmailName, 30)}</span>
                            <span className="email">{truncateText(address.fromEmailAddress, 50)}</span>
                        </div>
                    </MenuItem>
                )
            }
        </Select>
    </FormControl>;
};

export default AddressSelect;
